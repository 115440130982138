/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/less/bootstrap.less";

@import "../node_modules/primeng/resources/themes/rhea/theme.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeicons/primeicons.css";
@import (less) "./assets/css/se-icons.css";

// SE theme variables
@import "assets/less/se-variables";
// SE Bootstrap theme
@import "assets/less/se-bootstrap-variables";
// override Bootstrap default variables
@import "assets/less/se-theme";
// Application global variables and shared mixins/classes
@import "assets/less/app-variables";
@import "assets/less/app-mixins";
@import "assets/less/app-base";
@import "assets/less/app-additions";
@import "assets/less/shell-directive";
@import "assets/less/shell-additions";
@import "assets/less/index-style";

.fa-spinner {
  color: @spruceGreenColor;
}
