@import "se-variables.less";
@import "app-variables.less";

.p-datatable {
  padding: 10px;
  font-size: 14px;

  table {
    width: 100%;
  }

  input {
    font-size: 14px;
    width: 100%;
    min-width: 150px;
  }

  .p-datatable-header {
    border: none;
    background: none;
    color: unset;
  }
}

.p-paginator-page {
  font-size: 14px;
}
