// =======================
// SE theme for  Bootstrap
// =======================
@import "../../../node_modules/bootstrap/less/mixins.less";
@import "se-variables.less";
@import "app-variables.less";

// Bootstrap overrides
// -------------------
.page-header {
  padding-bottom: 5px;
  margin: @appMediumMargin 0;
}

h1, h2, h3 {
  margin: @appMediumMargin 0;
}

h5, .h5 {
  font-size: 15px;
}

// Buttons
// -------
// Helper mixin to quickly retheme the different button variants
.button-active-variant(@background; @border) {
  &:hover, &:focus {
    background-color: darken(@background, 5%);
    border-color: @border;
  }
  &:active {
    &:focus {
      background-color: darken(@background, 10%);
    }
  }
}

.btn {
  border: none;
  &:focus:active {
    outline:0;
  }
}

.btn-default-generic(@color) {
  outline: none;
  background-color: @color;

  &:hover {
    background-color: @newMediumLightGreyColor;
  }

  &:focus {
    background-color: @color;
    outline: none;

    &:active {
      background-color: @darkGreyColor;
      color: @seWhiteColor;
      transition: unset;
    }
  }

  &.dropdown-toggle {
    color: @seBlackColor;
    font-weight: bold;
    text-align: left;
  }
}

.btn-default-gray-background {
  .btn-default-generic(@seWhiteColor)
}

.btn-default-white-background {
  .btn-default-generic(@ultraLightGrey1bColor)
}

.btn-primary {
  .button-active-variant(@btn-primary-bg; @btn-primary-border);
}

.btn-warning {
  .button-active-variant(@btn-warning-bg; @btn-warning-border);
}

.btn-danger {
  .button-active-variant(@btn-danger-bg; @btn-danger-border);
}

.btn-info {
  .button-active-variant(@btn-info-bg; @btn-info-border);
}

.btn-toggle-white-background {

  box-shadow: unset;
  font-weight: normal;
  outline: none;
  color: @btn-default-color;
  background-color:@btn-default-bg;
  border-color: @btn-default-border;

  &:focus {
    outline: none;
  }

  &:hover {
    color: @btn-default-color;
    background-color: darken(@btn-default-bg, 5%);
    border-color: @btn-default-border;
  }
  &:active , &.active{
      color: @btn-primary-color;
      background-color: darken(@btn-primary-bg, 5%);
      border-color: @btn-primary-border;
    &:hover {
      background-color: darken(@btn-primary-bg, 10%);
    }
  }

  &.disabled {
    color: @btn-default-color;
    background-color:@btn-default-bg;
    border-color: @btn-default-border;
  }

}

.info-pellet {
  &:extend(.se-icons all, .se-Information all);
  font-size: 25px;
  color: @skyBlueColor;
  padding: 0;
  background-color: transparent;

  &:hover {
    color : darken(@skyBlueColor, 5%);
  }

  &:active {
    outline: unset;
    background-image: unset;
    box-shadow: unset;
    color : darken(@skyBlueColor, 10%);
  }
}

.form-horizontal .btn.btn-info-pellet {
  position: absolute;
  font-size: 25px;
  transform: translateY(-34%);
  border: unset;
  background-color: unset;
  outline:0;
  font-weight: normal;
  padding: 0;
}

.btn-validate{
  :active {
    background-color: darken(@btn-validate-bg, 5%);
  }
  .button-active-variant(@btn-validate-bg; @btn-validate-border);
  background-color: @btn-validate-bg;
  color: @btn-validate-color;
  margin-left: 40%; //Todo proper align center
  margin-top: 25px;
  margin-bottom: 25px;

  .disabled {
    background-color: @btn-validate-disable-bg;
    color: @btn-validate-disable-color;
    cursor: default;
    pointer-events: none;
  }
}

.btn-default, .btn-primary, .btn-success, .btn-info, .btn-warning, .btn-danger, btn-validate {
  &:active, &.active, .open .dropdown-toggle& {
    background: @superDarkGreyColor;
    color: @whiteColor;
  }

  &.disabled, &[disabled], fieldset[disabled] & {
    .opacity(0.55);
  }
}

.btn-flat, .btn-flat-inv {
  border-color: @whiteColor;
  font-family: @titleFontFamily;
  background-color: @whiteColor;
  color: @brand-info;

  &:hover, &:focus {
    background: @ultraLightGrey1bColor;
    border-color: @brand-info;
    color: @brand-info;
  }

  &:active, &.active, .open .dropdown-toggle& {
    background: @superDarkGreyColor;
    color: @whiteColor;
  }
}

.dropdown-toggle {
  &.disabled {
    background-color: @gray-lighter; //@superLightGreyColor;
    opacity: 1;
    border-color: @input-border;
    cursor: not-allowed;
  }
}

// Additional button variant
//.btn-invert {
//  background-color: @darkGreyColor;
//  border-color: @superLightGreyColor;
//  color: @superLightGreyColor;
//
//  &:hover, &:focus {
//    #gradient > .vertical(@darkGreyColor, darken(@darkGreyColor, 5%));
//    background-color: darken(@darkGreyColor, 2%);
//    color: @whiteColor;
//  }
//
//  &:active, &.active, .open .dropdown-toggle& {
//    #gradient > .vertical(@lightGreyColor, @whiteColor);
//    background-color: @whiteColor;
//    color: @darkGreyColor;
//  }
//}

// Fix caret vertical alignment for default button size
.btn .caret {
  margin-top: -2px;
}

// Scrollbars
//-----------
.scrollable {
  overflow-y: auto;

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #FFF -webkit-gradient(linear,
    40% 0%,
    75% 84%,
    from(@spruceGreenColor),
    to(@spruceGreenColor),
    color-stop(.2, @spruceGreenColor));
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px;
  }
}

// Dropdowns
// ---------
.btn-group.open .dropdown-toggle {
  .box-shadow(none);
}

.dropdown-menu {
  .box-shadow(~"0 0 10px rgba(0, 0, 0, 0.15)");
  border-color: @lightGreyColor;
  border-radius: 0;
  margin: 0;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  //  #gradient > .vertical(@anthraciteGreyColor, @darkGreyColor);
  background-color: @ultraLightGrey1bColor;
  color: @dropdown-link-color;
  //  font-family: @titleFontFamily;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  //  background: @darkGreyColor; //@ladyFernGreenColor;
  //  color: @whiteColor;
  font-weight: bold;
  //  font-family: @titleFontFamily;
}

.dropdown-menu {
  padding: 0;
}

// The last dropdown menu can expand not to be hidden
.characteristicsFieldset .form-horizontal:last-child .form-group:last-child .dropdown-menu {
  position: relative;
}

// General modal layout
// --------------------
@modalHeaderHeight: 42px;
@modalFooterHeight: 70px;
@modalFixedOffset: @modalHeaderHeight;
@modalIconWidth: @modalHeaderHeight;

.modal {
  overflow-y: inherit;
}

.modal-backdrop.in {
  .opacity(0.2);
}

.modal-fixed-height {
  height: 400px;
}

.modal-content {
  border-radius: 4px;
  border: 1px solid fade(@anthraciteGreyColor, 50%);
  background-color: @seWhiteColor;
  overflow: hidden;
  .box-shadow(0 0 10px 0 rgba(0, 0, 0, 0.15));
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.modal-header {
  background-color: @seOfficialLightGreenColor;
  border-bottom: 0; //5px solid @ladyFernGreenColor;
  padding: @appMediumMargin;

  .close {
    margin-top: 5px;
  }
}

.modal-title {
  font-size: 16px; //@appTitleFontSize;
  font-family: @appTitleFontFamily;
  color: @whiteColor;
  height: 25px; // ie fix
  .text-overflow;
}

.modal-footer {
  background-color: @seWhiteColor;
  padding: 0px 20px 20px 20px;
  margin-top: 0;
  border-top: 0;

  > button {
    font-weight: bold;
    min-width: 120px;
  }
}

.modal-close {
  padding: 0;
  border: 0;
  cursor: pointer;
  font-size: 12px;
  float: right;
  color: @whiteColor;
  background-color: transparent;
  -webkit-appearance: none;
  margin-left: @appMediumMargin;
  outline: none;
  &:hover, &.hover, &:focus, &.focus, &:active, &.active {
    color: @lightGreyColor;
  }
}

.modal-icon {
  float: left;
  height: @modalHeaderHeight;
  margin: -@appMediumMargin @appMediumMargin -@appMediumMargin -@appMediumMargin;
  width: @modalIconWidth;
  background-color: @seOfficialLightGreenColor;
  color: @whiteColor;
  line-height: @modalHeaderHeight;
  text-align: center;
  font-size: 1.5em;
}

.modal-title-info {
  .modal-title;
  color: @seOfficialLightGreenColor;
}

.modal-title-warning {
  .modal-title;
  color: @sunflowerYellowColor;
}

.modal-title-error {
  .modal-title;
  color: @seRedColor;
}

.modal-icon-info {
  .modal-icon;
  background-color: @seOfficialLightGreenColor;
}

.modal-icon-warning {
  .modal-icon;
  background-color: @sunflowerYellowColor;
}

.modal-icon-error {
  .modal-icon;
  background-color: @seRedColor;
}

.modal-wide {
  .modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: auto;
    height: auto;
    margin: @appMediumMargin !important;
  }

  .modal-content {
    height: 100%;
    overflow: auto;
  }

  .modal-body {
    position: absolute;
    left: 0;
    right: 0;
    top: @modalHeaderHeight;
    bottom: 0;
  }

  .modal-fixed-height {
    position: absolute;
    left: @appBigMargin;
    right: @appBigMargin;
    top: (@modalFixedOffset + @appBigMargin);
    bottom: @modalFooterHeight;
    height: auto;

    > .scrollable {
      overflow-x: hidden;
    }
  }

  .modal-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

// Panels
// ------
.panel {
  .box-shadow(none);
  margin-bottom: @appContentMargin;
}

.panel-heading, .panel-footer {
  padding: @appSmallMargin @appContentMargin;
}

.panel-body {
  padding: @appContentMargin;
}

// Special panels for split views
// ------------------------------
@seBackgroundContrastColor: @ultraLightGrey1Color;

.panel-content {
  border: 1px solid @seBackgroundContrastColor;
  border-radius: 0;
}

.panel-tool {
  border: 0;
  background-color: @seBackgroundContrastColor;

  > .panel-heading {
    color: @brand-info;
    border-bottom: 4px solid @brand-info;

    .h5 {
      color: @brand-info;
    }
  }

  .panel-body-wrapper {
    background-color: @appBackgroundColor;
  }
}

.panel-body-tight {
  padding: 0 @appMediumMargin @appMediumMargin @appMediumMargin;
}

// BUD/BID/BOM Panels
// ------------------
@seSpecsListHeight: 30px;

.specs-list {
  padding: 0 0 50px;

  > .title {
    height: @seSpecsListHeight;
    line-height: @seSpecsListHeight;
    font-size: 16px;
    background-color: @ultraLightGrey1bColor;
    margin-bottom: @appBigMargin;
  }

  //  > form {
  //    padding: 0 @appMediumMargin;
  //  }
}

.specs-list-middle {
  border-left: 2px solid @lightGreyColor;
  border-right: 2px solid @lightGreyColor;

  > .title {
    background-color: @ultraLightGrey1Color;
  }
}

.level-short {
  border-right: 1px solid @lightGreyColor;
  line-height: (@seSpecsListHeight - @appSmallMargin);
  padding: 0 @appMediumMargin;
}

.level-long {
  color: @darkGreyColor;
  padding: 0 @appMediumMargin;
}

.field-mandatory {
  &::after {
    content: ' *';
    color: @redColor;

    .has-error & {
      color: @brand-danger;
    }
  }
}

.field-permissive {
  &::after {
    content: ' *';
    color: @seOfficialLightGreenColor; //@ladyFernGreenColor;
  }
}

.specs-header {
  margin: -@appBigMargin -@appBigMargin 0 -@appBigMargin;
  padding: (@appBigMargin - 1px) @appBigMargin (@appMediumMargin - 1px) @appBigMargin;
  background: @seBackgroundContrastColor;
  border-bottom: 1px solid @lightGreyColor;
}

// ===============
// New form layout
// ===============
.form-horizontal {
  .btn {
    font-size: @formTextSize;
    padding: @formVerticalPadding @formHorizontalPadding;
    height: @formFieldHeight;
    font-weight: bold;
  }
}

.form-group {
  margin-bottom: @formSpacingBetweenFields;

  .form-control {
    font-size: @formTextSize;
    padding: @formVerticalPadding @formHorizontalPadding;
    height: @formFieldHeight;
    .box-shadow(none);
    //    color: @brand-info; // uncomment if text fields should be blue
    font-weight: bold;
  }

  .checkbox {
    padding: 0;
    height: @formFieldHeight;
    min-height: 0;

    input[type="checkbox"] {
      margin: @checkboxVerticalMargin 0;
      width: @checkboxSize;
      height: @checkboxSize;
    }
  }

  .radio {
    font-size: @formTextSize;
    padding-top: 3px;
    min-height: 0;

    input[type="radio"] {
      margin: @formVerticalPadding 0 @formVerticalPadding -20px;
      width: @checkboxSize;
      height: @checkboxSize;
    }
  }

  //  > .col-xs-6, > .col-xs-12, > .col-xs-4 {
  //    padding: 0;;
  //  }
  //
  //  > .col-xs-6.control-label, > .col-xs-6 label {
  //    padding-right: @appSmallMargin;
  //  }

  .control-label {
    font-size: @formTextSize;
    padding-top: 3px;
    text-align: left;
  }

  //  &.has-feedback .form-control-feedback {
  //    right: 0;
  //  }

  //  &.has-feedback >.col-xs-8 .form-control-feedback {
  //    right: @appMediumMargin;
  //  }
}

.input-group .form-control {
  font-size: @formTextSize;
  padding: @formVerticalPadding @formHorizontalPadding;
  height: @formFieldHeight;
}

.input-group-addon {
  font-size: @formTextSize;
  padding: @formVerticalPadding @formHorizontalPadding;
}

.has-feedback .form-control-feedback {
  width: @formFieldHeight;
  height: @formFieldHeight;
  line-height: @formFieldHeight;
}

.dropdown-custom-number-value, .dropdown-custom-value {
  margin-top: -@formSpacingBetweenFields / 2;
}

fieldset[disabled].radio > label {
  cursor: not-allowed;
  color: @gray-light;
}

// Wells
// -----
.well {
  padding: @appContentMargin;
  margin-bottom: @appContentMargin;
  border: 0;
  background-color: @seBackgroundContrastColor;
  .box-shadow(none);
  font-family: @titleFontFamily;
  color: @spruceGreenColor;
}

// Alerts
// ------
.alert-dismissable .close {
  top: inherit;
}

// List groups
// -----------
a.list-group-item.active .list-group-item-text,
a.list-group-item.active:hover .list-group-item-text,
a.list-group-item.active:focus .list-group-item-text {
  color: @whiteColor;
}

// Progress bars
// -------------
.progress {
  margin-bottom: @appContentMargin;
  border: 1px solid transparent;
}

.progress, .progress-bar {
  border-radius: 10px;
  .box-shadow(none);
}

// Additional class for smaller progress bars
.progress-small {
  height: 12px;
}

// Forms
// -----
.has-warning, .has-error, .has-success {
  .form-control:focus {
    .box-shadow(none);
  }
}

.form-control:focus {
  .box-shadow(none) !important;
}

// Typography
// ----------
// Override Bootstrap default darkening on hover
.text-primary {
  &:hover {
    color: @brand-primary;
  }
}

.text-warning {
  &:hover {
    color: @state-warning-text;
  }
}

.text-danger {
  &:hover {
    color: @state-danger-text;
  }
}

.text-success {
  &:hover {
    color: @state-success-text;
  }
}

.text-info {
  &:hover {
    color: @state-info-text;
  }
}

.control-label.text-left {
  text-align: left;
}

// Toastr
// ------
.toast {
  font-family: @appTextFontFamily;
  font-weight: normal;
}

body .ui-growl .ui-growl-item-container.ui-growl-message-success {
  background-color: @toastSuccessColor;
  color: @seWhiteColor;
  .ui-growl-image {
    color: @seWhiteColor;
    font-size: 2.5em;
  }
}

body .ui-growl .ui-growl-item-container.ui-growl-message-error {
  background-color: @toastErrorColor;
  color: @seWhiteColor;
  .ui-growl-image {
    color: @seWhiteColor;
    font-size: 2.5em;
  }
}

body .ui-growl .ui-growl-item-container.ui-growl-message-warn {
  background-color: @toastWarningColor;
  color: @seWhiteColor;
  .ui-growl-image {
    color: @seWhiteColor;
    font-size: 2.5em;
  }
}

body .ui-growl .ui-growl-item-container.ui-growl-message-info {
  background-color: @toastInfoColor;
  color: @seWhiteColor;
  .ui-growl-image {
    color: @seWhiteColor;
    font-size: 2.5em;
  }
}

.toast-bottom-right {
  bottom: (@appMediumMargin);
}

/* global application shadow box */
.box-shadow {
  -webkit-box-shadow: 1px 1px 5px 0px lightgrey; // iOS <4.3 & Android <4.1
  box-shadow: 1px 1px 5px 0px lightgrey;
}

.section-margin {
  padding-top: 35px;
}

/* REQUIRED LABEL OR INPUT */
.required-red-asterisk::after {
  content: " *";
  color: red;
  //position: absolute;
  //right: 0;
}

.se-close-cross-style {
  font-size: 25px;
  color: @superDarkGreyColor;
  font-weight: bold;
}
//Style for checkbox
.checkbox {
  padding-left: 20px;
  label {
    display: inline-block;
    position: relative;
    padding-left: 5px;
  }
  label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    top: -10px;
    margin-left: -20px;
    border: 1px solid @darkGreyColor;
    background-color: @whiteColor;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  }
  label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: -10px;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: @darkGreyColor;
  }
  input[type="checkbox"] {
    opacity: 0;
    &:disabled {
      + label {
        opacity: 0.65;
      }
      + label::before:enabled {
        background-color: @lightGreyColor;
        cursor: not-allowed;
      }
    }
    &:focus + label::before {
      outline: thin dotted;
      outline-offset: -2px;
    }
    &:checked + label::after {
      font-family: FontAwesome;
      content: "\f00c";
      font-weight: 900;
    }
    &:checked:disabled + label::before{
      background-color: @lightGreenColor;
    }
    &:checked {
      + label::before {
        background-color: @lightGreenColor;
        border-color: @lightGreenColor;
      }
      + label::after {
        color: @whiteColor;
      }
    }
  }
  &.checkbox-inline {
    margin-top: 0;
  }
  &.checkbox-circle label::before {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 50%;
  }
}

.info-color {
  color: @skyBlueColor;
}

// Custom checkbox
.custom-checkbox-container {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.disabled {
    font-weight: normal;
    color:@darkGreyColor;
    input ~ .checkmark {
      cursor: not-allowed;
      background-color: @lightGreyColor;
    }
  }

  input.checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      background-color: @spruceGreenColor;
      border: 0;
      &:after {
        display: block;
      }
    }
    &:disabled ~ .checkmark {
      cursor: not-allowed;
      background-color: @lightGreyColor;
    }
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 2px;
      width: 6px;
      height: 9px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
