// ==================
// SE theme variables
// ==================

// Color palette
// -------------
@darkSpruceGreenColor: #007626;
@superDarkGreenColor: #009530;
@darkGreenColor: #408700;
@spruceGreenColor: #3DCD58;
@ladyFernGreenColor: #4fa600;
@lightGreenColor: #59ba00;
@seedingGreenColor: #87d200;
@blueColor: #0087cd;
@mediumBlueColor: #219bfd;
//@blueAltColor:              #0ba5e1;  // alt. blue, in case linley one's is unreadable... (replace skyBlue)
@skyBlueColor: #42b4e6;
@fushiaRedColor: #b10043;
@redColor: #dc0a0a;
@warmRedColor: #dc3212;
@honeysuckleOrangeColor: #e47f00;
@sunflowerYellowColor: #ffd100;

@skyBlue10pColor: #ecf7fc;
@red10pColor: #fceae7;

// Toast Color
@toastSuccessColor: #4CAF50;
@toastErrorColor: #f44336;
@toastWarningColor: #FFB300;
@toastInfoColor: #2196f3;
// Grayscale color palette
// -----------------------
@trueBlackColor: #000000;
@blackColor: #241f0c;
@anthraciteGreyColor: #333333;
@ultraDarkGreyColor: #434343;
@megaDarkGreyColor: #4c4c4c; // linley color!
@superDarkGreyColor: #505559;
@darkGreyColor: #626469;
@mediumGreyColor: #9fa0a4;
@mediumLightGreyColor: #a6a6a6; // linley color!
@newMediumLightGreyColor: #E7E7E7;
@lightGreyColor: #cbcbcb;
@superLightGreyColor: #e6e6e6;
@ultraLightGrey1Color: #eaeaea;
@ultraLightGrey1bColor: #f5f5f5; // linley color!
@ultraLightGrey2Color: #f7f7f7;
@borderLineTableGrey: #dadada;

@whiteColor: #ffffff;

@transparentGrey2Color: rgba(0, 0, 0, .5);
@transparentBlueColor: rgba(0, 135, 205, .95);

// Additional (unofficial) colors
// ------------------------------
@mediumDarkGreyColor: #828489;
@supraLightGreyColor: #f2f2f2;
@honeysuckleOrange10pColor: mix(@honeysuckleOrangeColor, #fff, 10%);
@lightGreen10pColor: mix(@lightGreenColor, #fff, 10%);
@fushiaRed10pColor: mix(@fushiaRedColor, #fff, 10%);

// Gradient palette
// ----------------
@gradientDarkGreyColor2: @darkGreyColor;
@gradientDarkGreyColor1: @anthraciteGreyColor;
@gradientDarkGreyOffset: 100%;
@gradientLightGreyColor2: @lightGreyColor;
@gradientLightGreyColor1: @ultraLightGrey1Color;
@gradientLightGreyOffset: 100%;
@gradientSuperLightGrey1Color2: @superLightGreyColor;
@gradientSuperLightGrey1Color1: @ultraLightGrey2Color;
@gradientSuperLightGrey1Offset: 100%;
@gradientSuperLightGrey2Color2: @superLightGreyColor;
@gradientSuperLightGrey2Color1: @ultraLightGrey2Color;
@gradientSuperLightGrey2Offset: 30%;
@gradientUltraLightGreyColor2: @ultraLightGrey2Color;
@gradientUltraLightGreyColor1: @whiteColor;
@gradientUltraLightGreyOffset: 100%;
@gradientDarkGreenColor2: @darkGreenColor;
@gradientDarkGreenColor1: @ladyFernGreenColor;
@gradientDarkGreenColorOffset: 40%;
@gradientGreenColor2: @ladyFernGreenColor;
@gradientGreenColor1: @seedingGreenColor;
@gradientGreenOffset: 100%;
@gradientBlueColor2: @blueColor;
@gradientBlueColor1: @skyBlueColor;
@gradientBlueOffset: 100%;
@fontSizeSEIcons: 30px;

// Font palette
// ------------
@titleFontFamily: "SE Rounded Bold", Arial, Helvetica, sans-serif;
@textFontFamily: "SE Rounded Medium", "Arial MT", Arial, Helvetica, sans-serif;
@textBaseFontSize: 16px;
@textContentFontSize: 14px;

@font-face {
  font-family: 'SE Rounded Bold';
  src: url('../fonts/serounded-bol-webfont.woff') format('woff'),
  url('../fonts/serounded-bol-webfont.ttf') format('truetype');
}

@font-face {
  font-family: 'SE Rounded Medium';
  src: url('../fonts/serounded-med-webfont.woff') format('woff'),
  url('../fonts/serounded-med-webfont.ttf') format('truetype');
}

// Found from berrisoft (ga)
// TODO: Harmonize
// -----------------------
@seWhiteColor: @whiteColor;
@seTrueBlackColor: @trueBlackColor;
@seBlackColor: @blackColor;
@seAnthraciteGreyColor: @anthraciteGreyColor;
@seUltraDarkGreyColor: @ultraDarkGreyColor;
@seLightGreyColor: @lightGreyColor;
@seSunflowerYellowColor: @sunflowerYellowColor;
@seOfficialBlueColor: @skyBlueColor; // To avoid, use green instead !
@seOfficialLightGreenColor: #3DCD58; // Official green
@seOfficialDarkGreenColor: #009530; // To use to make a difference
@seLightGreenColor: @lightGreenColor;
@seSuperLightGreyColor: @superLightGreyColor;
@seRedColor: #dc0a0a; // Use for error
@seMediumGreyColor: @mediumGreyColor;
@seSuperDarkGreyColor: @superDarkGreyColor;
@seDarkGreyColor: @darkGreyColor;
@formInputErrorSize:                    13px;


