@font-face {
  font-family: "se-icon";
  src: url("~@se/icons/fonts/se-icon.woff2") format("woff2"), url("~@se/icons/fonts/se-icon.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
[class*=se-icon] {
  font-display: block;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "se-icon";
  font-style: normal;
  line-height: 1;
  position: relative;
  top: 1px;
  display: inline-block;
  font-size: 24px;
  font-weight: normal;
  font-variant: normal;
  text-decoration: inherit;
  font-stretch: normal;
  text-transform: none;
  font-size: inherit;
  text-rendering: auto;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Better Font Rendering */
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

.icon-style {
  cursor: pointer;
}

/* nunito-regular - latin_cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("~@se/fonts/fonts/nunito-v16-latin_cyrillic-regular.woff2") format("woff2"), url("~@se/fonts/fonts/nunito-v16-latin_cyrillic-regular.woff") format("woff"), url("~@se/fonts/fonts/nunito-v16-latin_cyrillic-regular.ttf") format("truetype"); /* Safari, Android, iOS */
}
/* nunito-bold - latin_cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("~@se/fonts/fonts/nunito-v16-latin_cyrillic-700.woff2") format("woff2"), url("~@se/fonts/fonts/nunito-v16-latin_cyrillic-700.woff") format("woff"), url("~@se/fonts/fonts/nunito-v16-latin_cyrillic-700.ttf") format("truetype"); /* Safari, Android, iOS */
}
.se-font-technical, .se-font, .se-app-body:not([class*=se-font-]) {
  font-family: Nunito, Roboto, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
}