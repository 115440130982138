.index-v-center {
  margin-top: 35vh;
}

.index-title {
  color: @spruceGreenColor;
  font-size: 30px;
  font-family: @titleFontFamily;
}

.index-center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.index-spinner {
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border: 16px solid @lightGreyColor;
  border-top: 16px solid @lightGreenColor;
  width: 120px;
  height: 120px;

}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
