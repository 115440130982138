@charset "UTF-8";
@font-face {
  font-family: "se-icon";
  src: url("../fonts/se-icon.eot");
  src: url("../fonts/se-icon.eot?#iefix") format("embedded-opentype"), url("../fonts/se-icon.woff") format("woff"), url("../fonts/se-icon.ttf") format("truetype"), url("../fonts/se-icon.svg#se-icon") format("svg");
  font-weight: normal;
  font-style: normal; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "se-icon";
    src: url("../fonts/se-icon.svg#se-icon") format("svg");
    font-display: block;}
}

[class*="se-icon"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "se-icon", fantasy;
  speak: none;
  font-style: normal;
  line-height: 1;
  position: relative;
  top: 1px;
  display: inline-block;
  font-size: 24px;
  font-weight: normal;
  font-variant: normal;
  text-decoration: inherit;
  font-stretch: normal;
  text-transform: none;
  text-rendering: auto;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
}

/* Specify the close icon style for toast of primeng v13.x.x */
.p-toast-icon-close-icon {
  font-family: primeicons, "se-icon", fantasy;
}

/* Specify the close icon style for multiselect of primeng v13.x.x */
.p-multiselect-close-icon::before {
  font-family: primeicons, "se-icon", fantasy;
}

.se-icon-2d:before {
  content: ""; }

.se-icon-3d_composite:before {
  content: ""; }

.se-icon-3d_convertor:before {
  content: ""; }

.se-icon-3d_viewer:before {
  content: ""; }

.se-icon-3d:before {
  content: ""; }

.se-icon-about:before {
  content: ""; }

.se-icon-action_add_flat:before {
  content: ""; }

.se-icon-action_attach:before {
  content: ""; }

.se-icon-action_ban:before {
  content: ""; }

.se-icon-action_copy:before {
  content: ""; }

.se-icon-action_cut:before {
  content: ""; }

.se-icon-action_datasheet:before {
  content: ""; }

.se-icon-action_delete_cross:before {
  content: ""; }

.se-icon-action_delete:before {
  content: ""; }

.se-icon-action_doc_add:before {
  content: ""; }

.se-icon-action_doc_export_stroke:before {
  content: ""; }

.se-icon-action_doc_export:before {
  content: ""; }

.se-icon-action_doc_import_stroke:before {
  content: ""; }

.se-icon-action_doc_import:before {
  content: ""; }

.se-icon-action_doc_new:before {
  content: ""; }

.se-icon-action_download_stroke:before {
  content: ""; }

.se-icon-action_download:before {
  content: ""; }

.se-icon-action_duplicate:before {
  content: ""; }

.se-icon-action_editor:before {
  content: ""; }

.se-icon-action_erase:before {
  content: ""; }

.se-icon-action_favourite_library_filled:before {
  content: ""; }

.se-icon-action_favourite_library:before {
  content: ""; }

.se-icon-action_filter:before {
  content: ""; }

.se-icon-action_generate_labels:before {
  content: ""; }

.se-icon-action_generate_symbols:before {
  content: ""; }

.se-icon-action_lock_off:before {
  content: ""; }

.se-icon-action_lock_on:before {
  content: ""; }

.se-icon-action_logout:before {
  content: ""; }

.se-icon-action_new_window:before {
  content: ""; }

.se-icon-action_new_with_wizzard:before {
  content: ""; }

.se-icon-action_next:before {
  content: ""; }

.se-icon-action_open_project:before {
  content: ""; }

.se-icon-action_open3:before {
  content: ""; }

.se-icon-action_paste:before {
  content: ""; }

.se-icon-action_pdf_report:before {
  content: ""; }

.se-icon-action_picture:before {
  content: ""; }

.se-icon-action_pin:before {
  content: ""; }

.se-icon-action_previous:before {
  content: ""; }

.se-icon-action_print_preview_closed:before {
  content: ""; }

.se-icon-action_print_preview:before {
  content: ""; }

.se-icon-action_print:before {
  content: ""; }

.se-icon-action_redo_closed:before {
  content: ""; }

.se-icon-action_redo:before {
  content: ""; }

.se-icon-action_report_closed:before {
  content: ""; }

.se-icon-action_report:before {
  content: ""; }

.se-icon-action_report2:before {
  content: ""; }

.se-icon-action_resize_xandy:before {
  content: ""; }

.se-icon-action_resize:before {
  content: ""; }

.se-icon-action_resizeonXandY:before {
  content: ""; }

.se-icon-action_save:before {
  content: ""; }

.se-icon-action_saveas:before {
  content: ""; }

.se-icon-action_scan_qrcode:before {
  content: ""; }

.se-icon-action_search_stroke:before {
  content: ""; }

.se-icon-action_search:before {
  content: ""; }

.se-icon-action_settings1:before {
  content: ""; }

.se-icon-action_settings2:before {
  content: ""; }

.se-icon-action_share:before {
  content: ""; }

.se-icon-action_sort:before {
  content: ""; }

.se-icon-action_test:before {
  content: ""; }

.se-icon-action_undo:before {
  content: ""; }

.se-icon-action_upgrade:before {
  content: ""; }

.se-icon-action_upload_stroke:before {
  content: ""; }

.se-icon-action_upload:before {
  content: ""; }

.se-icon-action_write_from_device:before {
  content: ""; }

.se-icon-action_write_from_mobile:before {
  content: ""; }

.se-icon-action_write_from_project:before {
  content: ""; }

.se-icon-action_write_from_system:before {
  content: ""; }

.se-icon-action_write_to_device:before {
  content: ""; }

.se-icon-action_write_to_mobile:before {
  content: ""; }

.se-icon-action_write_to_project:before {
  content: ""; }

.se-icon-action_write_to_system:before {
  content: ""; }

.se-icon-action_zoom_100_stroke:before {
  content: ""; }

.se-icon-action_zoom_100:before {
  content: ""; }

.se-icon-action_zoom_minus_stroke:before {
  content: ""; }

.se-icon-action_zoom_minus:before {
  content: ""; }

.se-icon-action_zoom_plus_stroke:before {
  content: ""; }

.se-icon-action_zoom_plus:before {
  content: ""; }

.se-icon-action_zoom_selected_area_stroke:before {
  content: ""; }

.se-icon-activity_feed:before {
  content: ""; }

.se-icon-address_book:before {
  content: ""; }

.se-icon-airconditioner_hot_heating:before {
  content: ""; }

.se-icon-airconditioner:before {
  content: ""; }

.se-icon-airconditioning_cold_cooling:before {
  content: ""; }

.se-icon-airconditioning:before {
  content: ""; }

.se-icon-alarm_clock:before {
  content: ""; }

.se-icon-analytics_stroke:before {
  content: ""; }

.se-icon-analytics:before {
  content: ""; }

.se-icon-architect:before {
  content: ""; }

.se-icon-archive:before {
  content: ""; }

.se-icon-arrow_above:before {
  content: ""; }

.se-icon-arrow_below:before {
  content: ""; }

.se-icon-arrow_long_up:before {
  content: ""; }

.se-icon-arrow_short_up:before {
  content: ""; }

.se-icon-arrow1_down_right:before {
  content: ""; }

.se-icon-arrow1_left:before {
  content: ""; }

.se-icon-arrow1_pointer_down:before {
  content: ""; }

.se-icon-arrow1_pointer_up:before {
  content: ""; }

.se-icon-arrow1_right:before {
  content: ""; }

.se-icon-arrow1_rotation:before {
  content: ""; }

.se-icon-arrow1_up_right:before {
  content: ""; }

.se-icon-arrow2_down:before {
  content: ""; }

.se-icon-arrow2_left:before {
  content: ""; }

.se-icon-arrow2_right:before {
  content: ""; }

.se-icon-arrow2_rotation:before {
  content: ""; }

.se-icon-arrow2_up:before {
  content: ""; }

.se-icon-arrow3_down:before {
  content: ""; }

.se-icon-arrow3_up:before {
  content: ""; }

.se-icon-arrow4_down:before {
  content: ""; }

.se-icon-arrow4_top:before {
  content: ""; }

.se-icon-asset_optimisation:before {
  content: ""; }

.se-icon-battery1_empty:before {
  content: ""; }

.se-icon-battery1_full:before {
  content: ""; }

.se-icon-battery1_general_left:before {
  content: ""; }

.se-icon-battery1_half:before {
  content: ""; }

.se-icon-battery1_low:before {
  content: ""; }

.se-icon-battery1_positioning:before {
  content: ""; }

.se-icon-battery1_test:before {
  content: ""; }

.se-icon-battery2_alternating_current:before {
  content: ""; }

.se-icon-battery2_charging:before {
  content: ""; }

.se-icon-battery2_check:before {
  content: ""; }

.se-icon-battery2_empty1:before {
  content: ""; }

.se-icon-battery2_empty2:before {
  content: ""; }

.se-icon-battery2_full:before {
  content: ""; }

.se-icon-battery2_general:before {
  content: ""; }

.se-icon-battery2_half:before {
  content: ""; }

.se-icon-battery2_lithium:before {
  content: ""; }

.se-icon-battery2_low:before {
  content: ""; }

.se-icon-battery2_ratio:before {
  content: ""; }

.se-icon-battery3_general:before {
  content: ""; }

.se-icon-bbq:before {
  content: ""; }

.se-icon-binoculars_closed:before {
  content: ""; }

.se-icon-binoculars:before {
  content: ""; }

.se-icon-body_ear:before {
  content: ""; }

.se-icon-body_eye:before {
  content: ""; }

.se-icon-body_hand1:before {
  content: ""; }

.se-icon-body_hand2:before {
  content: ""; }

.se-icon-body_handshake:before {
  content: ""; }

.se-icon-body_mouth:before {
  content: ""; }

.se-icon-book:before {
  content: ""; }

.se-icon-box1:before {
  content: ""; }

.se-icon-box2:before {
  content: ""; }

.se-icon-brightness_contrast:before {
  content: ""; }

.se-icon-brochure:before {
  content: ""; }

.se-icon-building_amphitheater:before {
  content: ""; }

.se-icon-building_badge_position:before {
  content: ""; }

.se-icon-building_flat:before {
  content: ""; }

.se-icon-building_occupants:before {
  content: ""; }

.se-icon-building_reading_room:before {
  content: ""; }

.se-icon-building_restaurant:before {
  content: ""; }

.se-icon-building_surface1:before {
  content: ""; }

.se-icon-building_surface2:before {
  content: ""; }

.se-icon-building_swimmingpool:before {
  content: ""; }

.se-icon-building_toilets:before {
  content: ""; }

.se-icon-building:before {
  content: ""; }

.se-icon-burger_menu:before {
  content: ""; }

.se-icon-burglar_alarm:before {
  content: ""; }

.se-icon-buy_digital_modules:before {
  content: ""; }

.se-icon-calculation_annotate_launch:before {
  content: ""; }

.se-icon-calculation_annotate_power_balance:before {
  content: ""; }

.se-icon-calculation_annotate_result:before {
  content: ""; }

.se-icon-calculation_launch_powerbalance:before {
  content: ""; }

.se-icon-calculation_result2:before {
  content: ""; }

.se-icon-calculation_results:before {
  content: ""; }

.se-icon-calculator:before {
  content: ""; }

.se-icon-calendar:before {
  content: ""; }

.se-icon-calender2:before {
  content: ""; }

.se-icon-carboy:before {
  content: ""; }

.se-icon-ceiling_fan:before {
  content: ""; }

.se-icon-central_unit:before {
  content: ""; }

.se-icon-child:before {
  content: ""; }

.se-icon-circuit_breaker:before {
  content: ""; }

.se-icon-cloud_download:before {
  content: ""; }

.se-icon-cloud_sync:before {
  content: ""; }

.se-icon-cloud_upload_stroke:before {
  content: ""; }

.se-icon-cloud_upload:before {
  content: ""; }

.se-icon-clutch:before {
  content: ""; }

.se-icon-co2_1:before {
  content: ""; }

.se-icon-co2_2:before {
  content: ""; }

.se-icon-color_palette:before {
  content: ""; }

.se-icon-comfort_boost:before {
  content: ""; }

.se-icon-comfort:before {
  content: ""; }

.se-icon-communication_bubbles1:before {
  content: ""; }

.se-icon-communication_bubbles2:before {
  content: ""; }

.se-icon-communication_bubbles3:before {
  content: ""; }

.se-icon-communication_view:before {
  content: ""; }

.se-icon-compact:before {
  content: ""; }

.se-icon-connect_device_directly:before {
  content: ""; }

.se-icon-connection_bluetooth:before {
  content: ""; }

.se-icon-connection_cloud_connection_error:before {
  content: ""; }

.se-icon-connection_cloud_connection:before {
  content: ""; }

.se-icon-connection_cloud_synchronize_device:before {
  content: ""; }

.se-icon-connection_direct:before {
  content: ""; }

.se-icon-connection_ethernet_closed:before {
  content: ""; }

.se-icon-connection_ethernet:before {
  content: ""; }

.se-icon-connection_gsm:before {
  content: ""; }

.se-icon-connection_router:before {
  content: ""; }

.se-icon-connection_sdcard:before {
  content: ""; }

.se-icon-connection_serial_port:before {
  content: ""; }

.se-icon-connection_usb_power:before {
  content: ""; }

.se-icon-connection_usb:before {
  content: ""; }

.se-icon-connection_wireless1:before {
  content: ""; }

.se-icon-connection_wireless2:before {
  content: ""; }

.se-icon-connection_zigbee:before {
  content: ""; }

.se-icon-continuity:before {
  content: ""; }

.se-icon-control_local:before {
  content: ""; }

.se-icon-control_manual:before {
  content: ""; }

.se-icon-control_remote:before {
  content: ""; }

.se-icon-cooking_plate:before {
  content: ""; }

.se-icon-country_china:before {
  content: ""; }

.se-icon-country_france:before {
  content: ""; }

.se-icon-country_spain:before {
  content: ""; }

.se-icon-create_quotation:before {
  content: ""; }

.se-icon-cup:before {
  content: ""; }

.se-icon-danger_hotsurface_stroke:before {
  content: ""; }

.se-icon-danger_hotsurface:before {
  content: ""; }

.se-icon-dashboard:before {
  content: ""; }

.se-icon-data_refresh_stroke:before {
  content: ""; }

.se-icon-data_refresh:before {
  content: ""; }

.se-icon-data_synchronizing:before {
  content: ""; }

.se-icon-data_transfer_in:before {
  content: ""; }

.se-icon-data_transfer_internal:before {
  content: ""; }

.se-icon-data_transfer_out:before {
  content: ""; }

.se-icon-data_transfer:before {
  content: ""; }

.se-icon-daynight_mode_day:before {
  content: ""; }

.se-icon-daynight_mode_night:before {
  content: ""; }

.se-icon-daynight_mode:before {
  content: ""; }

.se-icon-device_checkup:before {
  content: ""; }

.se-icon-diagnostic:before {
  content: ""; }

.se-icon-diesel_generator:before {
  content: ""; }

.se-icon-digital_view:before {
  content: ""; }

.se-icon-dimmer:before {
  content: ""; }

.se-icon-door_closed:before {
  content: ""; }

.se-icon-door_open:before {
  content: ""; }

.se-icon-doorway:before {
  content: ""; }

.se-icon-dropper:before {
  content: ""; }

.se-icon-earth_america:before {
  content: ""; }

.se-icon-earth_arrow:before {
  content: ""; }

.se-icon-earth_europe_africa:before {
  content: ""; }

.se-icon-earth_pacific:before {
  content: ""; }

.se-icon-ecomode_off:before {
  content: ""; }

.se-icon-ecomode_on:before {
  content: ""; }

.se-icon-electric_danger:before {
  content: ""; }

.se-icon-electric_hazard:before {
  content: ""; }

.se-icon-electric_heater:before {
  content: ""; }

.se-icon-electric_overload1:before {
  content: ""; }

.se-icon-electric_overload2:before {
  content: ""; }

.se-icon-electric_vehicle_terminal:before {
  content: ""; }

.se-icon-electrical_danger:before {
  content: ""; }

.se-icon-electrical_fault:before {
  content: ""; }

.se-icon-electrical_plug:before {
  content: ""; }

.se-icon-electrical_plug2:before {
  content: ""; }

.se-icon-electrical_plug3:before {
  content: ""; }

.se-icon-electrical_protection_IP21D_child:before {
  content: ""; }

.se-icon-electrical_scooter:before {
  content: ""; }

.se-icon-electrical_vehicle1:before {
  content: ""; }

.se-icon-electrical_vehicle2:before {
  content: ""; }

.se-icon-electricity_automatic_voltage_resolution:before {
  content: ""; }

.se-icon-electricity_busbar:before {
  content: ""; }

.se-icon-electricity_bypass:before {
  content: ""; }

.se-icon-electricity_capacitor_general_symbol:before {
  content: ""; }

.se-icon-electricity_circuitbreaker_trip:before {
  content: ""; }

.se-icon-electricity_classII_equipment:before {
  content: ""; }

.se-icon-electricity_critical_power:before {
  content: ""; }

.se-icon-electricity_current_alternating_view:before {
  content: ""; }

.se-icon-electricity_current_alternating:before {
  content: ""; }

.se-icon-electricity_current_bothdirect_alternating:before {
  content: ""; }

.se-icon-electricity_current_direct:before {
  content: ""; }

.se-icon-electricity_current_rectified:before {
  content: ""; }

.se-icon-electricity_dc_ac_converter:before {
  content: ""; }

.se-icon-electricity_discharge_protect:before {
  content: ""; }

.se-icon-electricity_fonctionnal_earthing:before {
  content: ""; }

.se-icon-electricity_fuse10a:before {
  content: ""; }

.se-icon-electricity_ground_fault_protection:before {
  content: ""; }

.se-icon-electricity_ground_noiseless:before {
  content: ""; }

.se-icon-electricity_ground_protective:before {
  content: ""; }

.se-icon-electricity_ground:before {
  content: ""; }

.se-icon-electricity_ohms:before {
  content: ""; }

.se-icon-electricity_polarity_negative_minus:before {
  content: ""; }

.se-icon-electricity_polarity_positive_plus:before {
  content: ""; }

.se-icon-electricity_protection_adjustment:before {
  content: ""; }

.se-icon-electricity_protection_adjustment2:before {
  content: ""; }

.se-icon-electricity_protection_earth_overcurrent:before {
  content: ""; }

.se-icon-electricity_protection_IP21Dchild:before {
  content: ""; }

.se-icon-electricity_protection_lightning:before {
  content: ""; }

.se-icon-electricity_protection_MVrelay:before {
  content: ""; }

.se-icon-electricity_protection_of_persons:before {
  content: ""; }

.se-icon-electricity_protection_phase_overcurrent:before {
  content: ""; }

.se-icon-electricity_protection_thermal_overload:before {
  content: ""; }

.se-icon-electricity_pulse_general:before {
  content: ""; }

.se-icon-electricity_socket_mennekes:before {
  content: ""; }

.se-icon-electricity_socket_shouco:before {
  content: ""; }

.se-icon-electricity_socket_type3:before {
  content: ""; }

.se-icon-electricity_switch_silence:before {
  content: ""; }

.se-icon-electricity_switch:before {
  content: ""; }

.se-icon-electricity_switchboard_hv:before {
  content: ""; }

.se-icon-electricity_switchboard_lv:before {
  content: ""; }

.se-icon-electricity_switchboard:before {
  content: ""; }

.se-icon-electricity_transformer_230V12V:before {
  content: ""; }

.se-icon-electricity_transformer:before {
  content: ""; }

.se-icon-electrostatic_devices:before {
  content: ""; }

.se-icon-elevator_closed:before {
  content: ""; }

.se-icon-elevator:before {
  content: ""; }

.se-icon-empty_set:before {
  content: ""; }

.se-icon-energy_efficiency:before {
  content: ""; }

.se-icon-energy:before {
  content: ""; }

.se-icon-event:before {
  content: ""; }

.se-icon-existing_project:before {
  content: ""; }

.se-icon-exit_presentation:before {
  content: ""; }

.se-icon-eye_dont_show:before {
  content: ""; }

.se-icon-face_happy:before {
  content: ""; }

.se-icon-face_meh:before {
  content: ""; }

.se-icon-face_unhappy:before {
  content: ""; }

.se-icon-favourite_insert:before {
  content: ""; }

.se-icon-favourites_addto:before {
  content: ""; }

.se-icon-fax:before {
  content: ""; }

.se-icon-feeder:before {
  content: ""; }

.se-icon-file_bim:before {
  content: ""; }

.se-icon-file_bom:before {
  content: ""; }

.se-icon-file_cad:before {
  content: ""; }

.se-icon-file_csv:before {
  content: ""; }

.se-icon-file_docx:before {
  content: ""; }

.se-icon-file_dwg:before {
  content: ""; }

.se-icon-file_eq:before {
  content: ""; }

.se-icon-file_ppt:before {
  content: ""; }

.se-icon-file_rfa:before {
  content: ""; }

.se-icon-file_xlsx:before {
  content: ""; }

.se-icon-file_xml:before {
  content: ""; }

.se-icon-fire_alarm:before {
  content: ""; }

.se-icon-fire_extinguisher:before {
  content: ""; }

.se-icon-fire:before {
  content: ""; }

.se-icon-firmware_upgrade:before {
  content: ""; }

.se-icon-first_aid:before {
  content: ""; }

.se-icon-fit_to_page:before {
  content: ""; }

.se-icon-flag_1:before {
  content: ""; }

.se-icon-flag_arrival:before {
  content: ""; }

.se-icon-flag_language:before {
  content: ""; }

.se-icon-flexibility:before {
  content: ""; }

.se-icon-fm_radio:before {
  content: ""; }

.se-icon-folder_add:before {
  content: ""; }

.se-icon-folder_delete:before {
  content: ""; }

.se-icon-folder_goto_parent:before {
  content: ""; }

.se-icon-folder_search:before {
  content: ""; }

.se-icon-folder:before {
  content: ""; }

.se-icon-frame_chassis:before {
  content: ""; }

.se-icon-furniture_armchair:before {
  content: ""; }

.se-icon-furniture_seat:before {
  content: ""; }

.se-icon-gas_heating:before {
  content: ""; }

.se-icon-gas_station:before {
  content: ""; }

.se-icon-gas:before {
  content: ""; }

.se-icon-general_danger_full:before {
  content: ""; }

.se-icon-general_danger_wired:before {
  content: ""; }

.se-icon-gesture_pointing:before {
  content: ""; }

.se-icon-gesture_selector:before {
  content: ""; }

.se-icon-gesture_selector2:before {
  content: ""; }

.se-icon-gesture_touch:before {
  content: ""; }

.se-icon-gesture_touch1:before {
  content: ""; }

.se-icon-get_rewards:before {
  content: ""; }

.se-icon-gift:before {
  content: ""; }

.se-icon-graph_barchart:before {
  content: ""; }

.se-icon-graph_down:before {
  content: ""; }

.se-icon-graph_nodata:before {
  content: ""; }

.se-icon-graph_threshold_down:before {
  content: ""; }

.se-icon-graph_threshold_exceed:before {
  content: ""; }

.se-icon-graph_threshold:before {
  content: ""; }

.se-icon-graph_up:before {
  content: ""; }

.se-icon-grid:before {
  content: ""; }

.se-icon-guided_quote:before {
  content: ""; }

.se-icon-head_phone:before {
  content: ""; }

.se-icon-headset:before {
  content: ""; }

.se-icon-heat_pump:before {
  content: ""; }

.se-icon-helmet:before {
  content: ""; }

.se-icon-help_readusermanuel_systemfault:before {
  content: ""; }

.se-icon-help_readusermanuel:before {
  content: ""; }

.se-icon-help_wired_flat:before {
  content: ""; }

.se-icon-help:before {
  content: ""; }

.se-icon-hifi:before {
  content: ""; }

.se-icon-history:before {
  content: ""; }

.se-icon-home:before {
  content: ""; }

.se-icon-horn_off:before {
  content: ""; }

.se-icon-horn_on:before {
  content: ""; }

.se-icon-hospital_nurse_call1:before {
  content: ""; }

.se-icon-hospital_nurse_call2:before {
  content: ""; }

.se-icon-hotel_donot_disturb:before {
  content: ""; }

.se-icon-hotel_key_card:before {
  content: ""; }

.se-icon-hotel_please_clean:before {
  content: ""; }

.se-icon-hotel_room_service:before {
  content: ""; }

.se-icon-hotel_solarium:before {
  content: ""; }

.se-icon-house_at_home:before {
  content: ""; }

.se-icon-house_automatic_watering:before {
  content: ""; }

.se-icon-house_bath:before {
  content: ""; }

.se-icon-house_bathroom:before {
  content: ""; }

.se-icon-house_bedroom:before {
  content: ""; }

.se-icon-house_bell:before {
  content: ""; }

.se-icon-house_blind_down:before {
  content: ""; }

.se-icon-house_blind_up:before {
  content: ""; }

.se-icon-house_blind:before {
  content: ""; }

.se-icon-house_blindslat_close:before {
  content: ""; }

.se-icon-house_blindslat_open:before {
  content: ""; }

.se-icon-house_boiler:before {
  content: ""; }

.se-icon-house_chicken_grill:before {
  content: ""; }

.se-icon-house_cooktop:before {
  content: ""; }

.se-icon-house_curtains_close:before {
  content: ""; }

.se-icon-house_curtains_open:before {
  content: ""; }

.se-icon-house_dining_room:before {
  content: ""; }

.se-icon-house_dishwasher:before {
  content: ""; }

.se-icon-house_dressing_room:before {
  content: ""; }

.se-icon-house_floor_heating:before {
  content: ""; }

.se-icon-house_freezer:before {
  content: ""; }

.se-icon-house_frontdoor:before {
  content: ""; }

.se-icon-house_garage_door_down:before {
  content: ""; }

.se-icon-house_garage_door_up:before {
  content: ""; }

.se-icon-house_garage_door:before {
  content: ""; }

.se-icon-house_garage:before {
  content: ""; }

.se-icon-house_garden:before {
  content: ""; }

.se-icon-house_home_locking:before {
  content: ""; }

.se-icon-house_home_unlocking:before {
  content: ""; }

.se-icon-house_in_vacation:before {
  content: ""; }

.se-icon-house_kitchen:before {
  content: ""; }

.se-icon-house_lounge_living_room:before {
  content: ""; }

.se-icon-house_metering:before {
  content: ""; }

.se-icon-house_outof_home:before {
  content: ""; }

.se-icon-house_oven_microwaves:before {
  content: ""; }

.se-icon-house_oven:before {
  content: ""; }

.se-icon-house_radiator:before {
  content: ""; }

.se-icon-house_refrigerator:before {
  content: ""; }

.se-icon-house_roller_blind_down:before {
  content: ""; }

.se-icon-house_roller_blind_up:before {
  content: ""; }

.se-icon-house_roller_blind:before {
  content: ""; }

.se-icon-house_shaver:before {
  content: ""; }

.se-icon-house_stairs:before {
  content: ""; }

.se-icon-house_swimming_pool:before {
  content: ""; }

.se-icon-house_terrace:before {
  content: ""; }

.se-icon-house_tumble_drier:before {
  content: ""; }

.se-icon-house_washing_machine:before {
  content: ""; }

.se-icon-house_water_heater_electric:before {
  content: ""; }

.se-icon-house_water_heater_solar:before {
  content: ""; }

.se-icon-house_window2:before {
  content: ""; }

.se-icon-housedoor1:before {
  content: ""; }

.se-icon-household:before {
  content: ""; }

.se-icon-hub_connection_error:before {
  content: ""; }

.se-icon-hub_connection_success:before {
  content: ""; }

.se-icon-hub_connection:before {
  content: ""; }

.se-icon-incomer:before {
  content: ""; }

.se-icon-information_circle:before {
  content: ""; }

.se-icon-information_sheet:before {
  content: ""; }

.se-icon-information_stroke:before {
  content: ""; }

.se-icon-input_output_in:before {
  content: ""; }

.se-icon-input_output_out:before {
  content: ""; }

.se-icon-input_output:before {
  content: ""; }

.se-icon-internet:before {
  content: ""; }

.se-icon-ionizing_radiation:before {
  content: ""; }

.se-icon-it_data_raw:before {
  content: ""; }

.se-icon-it_data_set:before {
  content: ""; }

.se-icon-it_data:before {
  content: ""; }

.se-icon-it_embedded_HMI:before {
  content: ""; }

.se-icon-it_keyboard:before {
  content: ""; }

.se-icon-it_laptop:before {
  content: ""; }

.se-icon-it_pc:before {
  content: ""; }

.se-icon-it_screen:before {
  content: ""; }

.se-icon-it_server:before {
  content: ""; }

.se-icon-it_smartphone:before {
  content: ""; }

.se-icon-it_web_browser:before {
  content: ""; }

.se-icon-key:before {
  content: ""; }

.se-icon-knx:before {
  content: ""; }

.se-icon-lamp_ceiling:before {
  content: ""; }

.se-icon-lamp_compact_fluorescent:before {
  content: ""; }

.se-icon-lamp_floor:before {
  content: ""; }

.se-icon-lamp_spotlight:before {
  content: ""; }

.se-icon-launch_device_discovery:before {
  content: ""; }

.se-icon-library:before {
  content: ""; }

.se-icon-light_group_off:before {
  content: ""; }

.se-icon-light_group_on:before {
  content: ""; }

.se-icon-light_half:before {
  content: ""; }

.se-icon-light_off:before {
  content: ""; }

.se-icon-light_on:before {
  content: ""; }

.se-icon-light_tube:before {
  content: ""; }

.se-icon-lighting_lowIntensity:before {
  content: ""; }

.se-icon-lighting_no_blackout:before {
  content: ""; }

.se-icon-lighting_off:before {
  content: ""; }

.se-icon-lighting_test:before {
  content: ""; }

.se-icon-lighting:before {
  content: ""; }

.se-icon-lightning:before {
  content: ""; }

.se-icon-list:before {
  content: ""; }

.se-icon-load_profile:before {
  content: ""; }

.se-icon-load100_percent:before {
  content: ""; }

.se-icon-loading:before {
  content: ""; }

.se-icon-locate_me:before {
  content: ""; }

.se-icon-location_map:before {
  content: ""; }

.se-icon-location:before {
  content: ""; }

.se-icon-logo_android_sdk:before {
  content: ""; }

.se-icon-logo_Ecostruxure:before {
  content: ""; }

.se-icon-logo_Facebook_circle:before {
  content: ""; }

.se-icon-logo_Facebook:before {
  content: ""; }

.se-icon-logo_Google_plus_circle:before {
  content: ""; }

.se-icon-logo_Google_plus:before {
  content: ""; }

.se-icon-logo_Linkedin_circle:before {
  content: ""; }

.se-icon-logo_Linkedin:before {
  content: ""; }

.se-icon-logo_Odnoklassniki_circle:before {
  content: ""; }

.se-icon-logo_Odnoklassniki:before {
  content: ""; }

.se-icon-logo_os_sdk:before {
  content: ""; }

.se-icon-logo_QQ_circle:before {
  content: ""; }

.se-icon-logo_QQ:before {
  content: ""; }

.se-icon-logo_Schneider_Electric_circle:before {
  content: ""; }

.se-icon-logo_Schneider_Electric_logotype:before {
  content: ""; }

.se-icon-logo_Schneider_Electric:before {
  content: ""; }

.se-icon-logo_Twitter_circle:before {
  content: ""; }

.se-icon-logo_Twitter:before {
  content: ""; }

.se-icon-logo_Vkontakte_circle:before {
  content: ""; }

.se-icon-logo_Vkontakte:before {
  content: ""; }

.se-icon-logo_Wechat_circle:before {
  content: ""; }

.se-icon-logo_Wechat:before {
  content: ""; }

.se-icon-logo_Weibo_circle:before {
  content: ""; }

.se-icon-logo_Weibo:before {
  content: ""; }

.se-icon-logo_Wiser:before {
  content: ""; }

.se-icon-logo_Youtube_circle:before {
  content: ""; }

.se-icon-logo_Youtube:before {
  content: ""; }

.se-icon-loudspeaker_on_1:before {
  content: ""; }

.se-icon-loudspeaker_on:before {
  content: ""; }

.se-icon-mail_forward:before {
  content: ""; }

.se-icon-mail_get_closed:before {
  content: ""; }

.se-icon-mail_get_opened:before {
  content: ""; }

.se-icon-mail_new:before {
  content: ""; }

.se-icon-mail_new2:before {
  content: ""; }

.se-icon-mail_reply:before {
  content: ""; }

.se-icon-mail_replyall_closed:before {
  content: ""; }

.se-icon-mail_replyall:before {
  content: ""; }

.se-icon-mail_send_closed:before {
  content: ""; }

.se-icon-mail_send_paper_plane_perspective:before {
  content: ""; }

.se-icon-mail_send_paper_plane:before {
  content: ""; }

.se-icon-mail_send:before {
  content: ""; }

.se-icon-mail:before {
  content: ""; }

.se-icon-mail1:before {
  content: ""; }

.se-icon-maintenance_closed:before {
  content: ""; }

.se-icon-maintenance_scheduled:before {
  content: ""; }

.se-icon-man_in_motion:before {
  content: ""; }

.se-icon-man_running:before {
  content: ""; }

.se-icon-man_with_headset:before {
  content: ""; }

.se-icon-management:before {
  content: ""; }

.se-icon-manual_control:before {
  content: ""; }

.se-icon-map_location:before {
  content: ""; }

.se-icon-map:before {
  content: ""; }

.se-icon-measurement_ampmeter:before {
  content: ""; }

.se-icon-measurement_metering:before {
  content: ""; }

.se-icon-measurement_voltmeter:before {
  content: ""; }

.se-icon-media_camera:before {
  content: ""; }

.se-icon-media_microphone1:before {
  content: ""; }

.se-icon-media_microphone2:before {
  content: ""; }

.se-icon-media_music_list:before {
  content: ""; }

.se-icon-media_music:before {
  content: ""; }

.se-icon-media_photo_gallery:before {
  content: ""; }

.se-icon-media_Picture1:before {
  content: ""; }

.se-icon-media_player_continued:before {
  content: ""; }

.se-icon-media_player_fastrun:before {
  content: ""; }

.se-icon-media_player_pause:before {
  content: ""; }

.se-icon-media_player_playrun:before {
  content: ""; }

.se-icon-media_player_repeat:before {
  content: ""; }

.se-icon-media_player_stop:before {
  content: ""; }

.se-icon-media_tv_watching:before {
  content: ""; }

.se-icon-media_tv:before {
  content: ""; }

.se-icon-media_video:before {
  content: ""; }

.se-icon-media:before {
  content: ""; }

.se-icon-meeting_request:before {
  content: ""; }

.se-icon-microscope:before {
  content: ""; }

.se-icon-mobile_connection:before {
  content: ""; }

.se-icon-module_ip:before {
  content: ""; }

.se-icon-module_ip3:before {
  content: ""; }

.se-icon-moment:before {
  content: ""; }

.se-icon-money_savings:before {
  content: ""; }

.se-icon-money:before {
  content: ""; }

.se-icon-motion_sensor:before {
  content: ""; }

.se-icon-motor:before {
  content: ""; }

.se-icon-move:before {
  content: ""; }

.se-icon-nature_flower:before {
  content: ""; }

.se-icon-nature_tree:before {
  content: ""; }

.se-icon-nature_trees:before {
  content: ""; }

.se-icon-network_draw:before {
  content: ""; }

.se-icon-new_folder:before {
  content: ""; }

.se-icon-new_project:before {
  content: ""; }

.se-icon-nfc_label:before {
  content: ""; }

.se-icon-nfc:before {
  content: ""; }

.se-icon-notebook_paper:before {
  content: ""; }

.se-icon-notification_critical_stroke:before {
  content: ""; }

.se-icon-notification_critical:before {
  content: ""; }

.se-icon-notification_error_full_stroke:before {
  content: ""; }

.se-icon-notification_error_plain:before {
  content: ""; }

.se-icon-notification_error:before {
  content: ""; }

.se-icon-notification_historic_critical:before {
  content: ""; }

.se-icon-notification_historic:before {
  content: ""; }

.se-icon-notification_off:before {
  content: ""; }

.se-icon-notification_ok_stroke:before {
  content: ""; }

.se-icon-notification_ok_wired:before {
  content: ""; }

.se-icon-notification_ok:before {
  content: ""; }

.se-icon-notification_on:before {
  content: ""; }

.se-icon-onoff_off:before {
  content: ""; }

.se-icon-onoff_on:before {
  content: ""; }

.se-icon-onoff_standby:before {
  content: ""; }

.se-icon-onoff:before {
  content: ""; }

.se-icon-open_book:before {
  content: ""; }

.se-icon-other_plain:before {
  content: ""; }

.se-icon-other_vertical:before {
  content: ""; }

.se-icon-other:before {
  content: ""; }

.se-icon-outdoor_lighting:before {
  content: ""; }

.se-icon-packaging_clamp_here:before {
  content: ""; }

.se-icon-packaging_discard:before {
  content: ""; }

.se-icon-packaging_donot_clamp_here:before {
  content: ""; }

.se-icon-packaging_donot_step_here:before {
  content: ""; }

.se-icon-packaging_fragile:before {
  content: ""; }

.se-icon-packaging_hand_truck_here:before {
  content: ""; }

.se-icon-packaging_handlewithcare:before {
  content: ""; }

.se-icon-packaging_no_stacking:before {
  content: ""; }

.se-icon-packaging_nohand_truck:before {
  content: ""; }

.se-icon-packaging_recyclable:before {
  content: ""; }

.se-icon-packaging_sling_here:before {
  content: ""; }

.se-icon-packaging_stacking_limit:before {
  content: ""; }

.se-icon-packaging_stacking_limitation:before {
  content: ""; }

.se-icon-packaging_temperature_levels:before {
  content: ""; }

.se-icon-packaging_this_way_up:before {
  content: ""; }

.se-icon-page:before {
  content: ""; }

.se-icon-paper_stack:before {
  content: ""; }

.se-icon-pen:before {
  content: ""; }

.se-icon-phase_break:before {
  content: ""; }

.se-icon-phase_overcurrent_fault:before {
  content: ""; }

.se-icon-phone_ip:before {
  content: ""; }

.se-icon-phone:before {
  content: ""; }

.se-icon-please_wait:before {
  content: ""; }

.se-icon-plug_and_play:before {
  content: ""; }

.se-icon-presence_sensor_closed:before {
  content: ""; }

.se-icon-presence_sensor:before {
  content: ""; }

.se-icon-presentation_mode:before {
  content: ""; }

.se-icon-pricing_eur:before {
  content: ""; }

.se-icon-pricing_usd:before {
  content: ""; }

.se-icon-printer:before {
  content: ""; }

.se-icon-product_selector:before {
  content: ""; }

.se-icon-project_management:before {
  content: ""; }

.se-icon-project:before {
  content: ""; }

.se-icon-projection:before {
  content: ""; }

.se-icon-puzzle1:before {
  content: ""; }

.se-icon-puzzle2:before {
  content: ""; }

.se-icon-quotation:before {
  content: ""; }

.se-icon-rack_new:before {
  content: ""; }

.se-icon-rating_1star:before {
  content: ""; }

.se-icon-rating_2stars:before {
  content: ""; }

.se-icon-rating_3stars:before {
  content: ""; }

.se-icon-refresh:before {
  content: ""; }

.se-icon-repartitor:before {
  content: ""; }

.se-icon-reset:before {
  content: ""; }

.se-icon-ruler:before {
  content: ""; }

.se-icon-run:before {
  content: ""; }

.se-icon-s1:before {
  content: ""; }

.se-icon-saw:before {
  content: ""; }

.se-icon-scan_barcode:before {
  content: ""; }

.se-icon-scan_nfc:before {
  content: ""; }

.se-icon-screwdriver:before {
  content: ""; }

.se-icon-screwless:before {
  content: ""; }

.se-icon-se-icon:before {
  content: ""; }

.se-icon-segment_airport:before {
  content: ""; }

.se-icon-segment_automotive:before {
  content: ""; }

.se-icon-segment_beer:before {
  content: ""; }

.se-icon-segment_biscuit:before {
  content: ""; }

.se-icon-segment_building_small:before {
  content: ""; }

.se-icon-segment_building:before {
  content: ""; }

.se-icon-segment_cement:before {
  content: ""; }

.se-icon-segment_datacentre:before {
  content: ""; }

.se-icon-segment_drinking_water:before {
  content: ""; }

.se-icon-segment_drinks:before {
  content: ""; }

.se-icon-segment_education:before {
  content: ""; }

.se-icon-segment_electrical_energy:before {
  content: ""; }

.se-icon-segment_electronic_surveillance_multiple:before {
  content: ""; }

.se-icon-segment_electronic_surveillance:before {
  content: ""; }

.se-icon-segment_energy_infrastructure:before {
  content: ""; }

.se-icon-segment_food_and_beverages:before {
  content: ""; }

.se-icon-segment_hoisting:before {
  content: ""; }

.se-icon-segment_hospital:before {
  content: ""; }

.se-icon-segment_hotels:before {
  content: ""; }

.se-icon-segment_hydro:before {
  content: ""; }

.se-icon-segment_industry:before {
  content: ""; }

.se-icon-segment_it:before {
  content: ""; }

.se-icon-segment_machines:before {
  content: ""; }

.se-icon-segment_marine:before {
  content: ""; }

.se-icon-segment_material_handling:before {
  content: ""; }

.se-icon-segment_medical:before {
  content: ""; }

.se-icon-segment_milk:before {
  content: ""; }

.se-icon-segment_mining_minerals_metals:before {
  content: ""; }

.se-icon-segment_mining:before {
  content: ""; }

.se-icon-segment_nuclear:before {
  content: ""; }

.se-icon-segment_og_general:before {
  content: ""; }

.se-icon-segment_og_offshore:before {
  content: ""; }

.se-icon-segment_og_onshore:before {
  content: ""; }

.se-icon-segment_og_pipeline:before {
  content: ""; }

.se-icon-segment_og_refinery:before {
  content: ""; }

.se-icon-segment_og_tankfarm:before {
  content: ""; }

.se-icon-segment_packaging:before {
  content: ""; }

.se-icon-segment_panel_builder:before {
  content: ""; }

.se-icon-segment_petfood:before {
  content: ""; }

.se-icon-segment_rail:before {
  content: ""; }

.se-icon-segment_residential:before {
  content: ""; }

.se-icon-segment_retail:before {
  content: ""; }

.se-icon-segment_textile:before {
  content: ""; }

.se-icon-segment_thermal_power:before {
  content: ""; }

.se-icon-segment_wastewater:before {
  content: ""; }

.se-icon-segment_water:before {
  content: ""; }

.se-icon-select_wiring_devices:before {
  content: ""; }

.se-icon-selection_check:before {
  content: ""; }

.se-icon-sensor2:before {
  content: ""; }

.se-icon-sensors:before {
  content: ""; }

.se-icon-shuffle:before {
  content: ""; }

.se-icon-signal_high:before {
  content: ""; }

.se-icon-signal_low:before {
  content: ""; }

.se-icon-signal_middle:before {
  content: ""; }

.se-icon-site:before {
  content: ""; }

.se-icon-sld_export:before {
  content: ""; }

.se-icon-sld:before {
  content: ""; }

.se-icon-smart_panel:before {
  content: ""; }

.se-icon-solar_irradiance1:before {
  content: ""; }

.se-icon-solar_irradiance2:before {
  content: ""; }

.se-icon-solar_irradiance3:before {
  content: ""; }

.se-icon-solar_panel1:before {
  content: ""; }

.se-icon-solar_panel2:before {
  content: ""; }

.se-icon-spim:before {
  content: ""; }

.se-icon-spinner:before {
  content: ""; }

.se-icon-sport:before {
  content: ""; }

.se-icon-spring_compressed:before {
  content: ""; }

.se-icon-spring_released:before {
  content: ""; }

.se-icon-star_plain:before {
  content: ""; }

.se-icon-star_stroke:before {
  content: ""; }

.se-icon-status_inquiry:before {
  content: ""; }

.se-icon-stretch:before {
  content: ""; }

.se-icon-success_victory:before {
  content: ""; }

.se-icon-suitcase:before {
  content: ""; }

.se-icon-switchboard_add:before {
  content: ""; }

.se-icon-switchboard_info:before {
  content: ""; }

.se-icon-table:before {
  content: ""; }

.se-icon-tablet:before {
  content: ""; }

.se-icon-task_management:before {
  content: ""; }

.se-icon-task:before {
  content: ""; }

.se-icon-temp_auto:before {
  content: ""; }

.se-icon-temperature_cold:before {
  content: ""; }

.se-icon-temperature_hot:before {
  content: ""; }

.se-icon-temperature_profile:before {
  content: ""; }

.se-icon-temperature1:before {
  content: ""; }

.se-icon-temperature2:before {
  content: ""; }

.se-icon-test_results_nok:before {
  content: ""; }

.se-icon-test:before {
  content: ""; }

.se-icon-thermostat:before {
  content: ""; }

.se-icon-thumb_down:before {
  content: ""; }

.se-icon-thumb_up:before {
  content: ""; }

.se-icon-time_chronometer:before {
  content: ""; }

.se-icon-time_saving:before {
  content: ""; }

.se-icon-timer_programmable:before {
  content: ""; }

.se-icon-timer:before {
  content: ""; }

.se-icon-toggle_switch:before {
  content: ""; }

.se-icon-tools:before {
  content: ""; }

.se-icon-towel_dryer2:before {
  content: ""; }

.se-icon-tripping_curve:before {
  content: ""; }

.se-icon-umbrella:before {
  content: ""; }

.se-icon-user_add_group:before {
  content: ""; }

.se-icon-user_add:before {
  content: ""; }

.se-icon-user_admin:before {
  content: ""; }

.se-icon-user_contractor:before {
  content: ""; }

.se-icon-user_distributor:before {
  content: ""; }

.se-icon-user_electrician:before {
  content: ""; }

.se-icon-user_group:before {
  content: ""; }

.se-icon-user_invite:before {
  content: ""; }

.se-icon-user_management:before {
  content: ""; }

.se-icon-user_oem:before {
  content: ""; }

.se-icon-user_phone_contact:before {
  content: ""; }

.se-icon-user_registration_authentication:before {
  content: ""; }

.se-icon-user_remove_group:before {
  content: ""; }

.se-icon-user_remove:before {
  content: ""; }

.se-icon-user_standard:before {
  content: ""; }

.se-icon-user_systemintegrator:before {
  content: ""; }

.se-icon-validate:before {
  content: ""; }

.se-icon-venitian_shutter_closed:before {
  content: ""; }

.se-icon-venitian_shutter_middle:before {
  content: ""; }

.se-icon-venitian_shutter_open:before {
  content: ""; }

.se-icon-ventilation_high:before {
  content: ""; }

.se-icon-ventilation_slow:before {
  content: ""; }

.se-icon-vmc:before {
  content: ""; }

.se-icon-vmc2:before {
  content: ""; }

.se-icon-water_drop_closed:before {
  content: ""; }

.se-icon-weather_blizzard:before {
  content: ""; }

.se-icon-weather_clear_night:before {
  content: ""; }

.se-icon-weather_cloudy:before {
  content: ""; }

.se-icon-weather_drizzle:before {
  content: ""; }

.se-icon-weather_dust:before {
  content: ""; }

.se-icon-weather_fog:before {
  content: ""; }

.se-icon-weather_freezing_rain:before {
  content: ""; }

.se-icon-weather_haze:before {
  content: ""; }

.se-icon-weather_heavy_rain_showers:before {
  content: ""; }

.se-icon-weather_heavy_rain:before {
  content: ""; }

.se-icon-weather_light_rain_showers:before {
  content: ""; }

.se-icon-weather_light_rain:before {
  content: ""; }

.se-icon-weather_mostly_sunny:before {
  content: ""; }

.se-icon-weather_partly_cloudy:before {
  content: ""; }

.se-icon-weather_rain_snow_mix:before {
  content: ""; }

.se-icon-weather_rainy:before {
  content: ""; }

.se-icon-weather_sleet_or_freezing_drizzlle:before {
  content: ""; }

.se-icon-weather_sleet_snow_mix:before {
  content: ""; }

.se-icon-weather_smoke:before {
  content: ""; }

.se-icon-weather_snow_flurries:before {
  content: ""; }

.se-icon-weather_snow_showers:before {
  content: ""; }

.se-icon-weather_snow:before {
  content: ""; }

.se-icon-weather_snowy:before {
  content: ""; }

.se-icon-weather_sunny:before {
  content: ""; }

.se-icon-weather_thunderstorms:before {
  content: ""; }

.se-icon-weather_thundery_showers:before {
  content: ""; }

.se-icon-weather_very_hot_and_humid:before {
  content: ""; }

.se-icon-weather_windy:before {
  content: ""; }

.se-icon-web_sdk:before {
  content: ""; }

.se-icon-webview:before {
  content: ""; }

.se-icon-wifi_nosignal:before {
  content: ""; }

.se-icon-wifi_signal0:before {
  content: ""; }

.se-icon-wifi_signal100:before {
  content: ""; }

.se-icon-wifi_signal25:before {
  content: ""; }

.se-icon-wifi_signal75:before {
  content: ""; }

.se-icon-wind_speed_high:before {
  content: ""; }

.se-icon-wind_speed_low:before {
  content: ""; }

.se-icon-wind_turbine:before {
  content: ""; }

.se-icon-windfarm:before {
  content: ""; }

.se-icon-window_cascade:before {
  content: ""; }

.se-icon-window_column_multi:before {
  content: ""; }

.se-icon-window_column_single:before {
  content: ""; }

.se-icon-window_fullscreen:before {
  content: ""; }

.se-icon-window_tiles:before {
  content: ""; }

.se-icon-wrench:before {
  content: ""; }

.icon-style {
  cursor: pointer; }
