// ========================
// Application global style
// ========================
@import "app-variables.less";

// ui-grid
// ----------------------------------
.ui-grid-header-canvas .ui-grid-header-cell .cell-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;
  > .ui-grid-cell-contents {
    align-self: flex-start;
    width: 100%;
  }
  > .ui-grid-filter {
    align-self: flex-end;
    width: 100%;
    > .ui-grid-filter-container {
      padding: 4px 5px;
    }
  }
}

// Additional style for form controls
// ----------------------------------
.btn-group-horizontal-fill {
  width: 100%;

  > .btn {
    width: 100%;
    //    text-align: left;

    > .btn-title {
      float: left; // fix for Firefox
    }

    > .caret {
      float: right;
      margin-top: 7px;
      margin-left: @appSmallMargin;
    }
  }
}

// Fix for Bootstrap .has-feedback/.input-group-addon incompatibility
// ------------------------------------------------------------------
.has-feedback .input-group .form-control {
  padding-right: @formHorizontalPadding;
}

.has-error .input-group-addon {
  padding-right: (@appBigMargin + @appSmallMargin);
}

.dropdown-horizontal-fill {
  min-width: 100%;
}

// Additional column style for grid
// --------------------------------
.no-padding-left {
  padding-left: 0;
}

.no-padding-right {
  padding-right: 0;
}

.no-padding {
  padding: 0;
}

// Additional style for forms
// --------------------------
.no-margin-bottom {
  margin-bottom: 0;
}

// View panel title
// ----------------
@panelHeadingTitleHeight: 38px;
@panelFooterHeight: 51px;

.panel-heading-title {
  height: @panelHeadingTitleHeight;
  line-height: (@panelHeadingTitleHeight - 2 * @appSmallMargin);
}

.panel-heading-fixed {
  position: absolute;
  width: 100%;
  top: 0;
}

.panel-default > .panel-heading-clear {
  background: none;
  border-color: transparent;
}

.panel-body-fill {
  position: absolute;
  top: @panelHeadingTitleHeight;
  bottom: 0;
  left: 0;
  right: 0;

  &.with-bottom-buttons {
    bottom: @panelFooterHeight;
  }

  &.scrollable {
    height: auto;
    width: auto;
  }
}

// "fake" panel for accordions
.panel-fake {
  margin-bottom: 0;
}

.panel-bottom-buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: @appMediumMargin;
  background: transparent;

  button {
    min-width: 120px;
  }
}

// Alerts
// ------
.alert-sm {
  padding: @padding-small-vertical @padding-small-horizontal;
  line-height: @line-height-small;
  font-size: @font-size-small;
}

// Font awesome additions
// ----------------------
// Fix font size for too small icon having rendering issues in .btn-xs button size
.fa-clear {
  font-size: 14px;
}

// Automatic chevron
.fa-chevron-auto {
  &:before {
    content: "\f078";
  }

  .collapsed &:before {
    content: "\f054";
  }
}

// Special-styled tooltips
// -----------------------

// To be displayed over modals (which have z-index to 1050)
.tooltip {
  z-index: 9999;
  .tooltip-multiline {
    white-space: pre-line;
    text-align: left;
  }
}

// Danger
.danger-tooltip {
  .tooltip-inner {
    color: @whiteColor;
    background-color: @state-danger-text;
  }

  .top > .tooltip-arrow {
    border-top-color: @state-danger-text;
  }

  .bottom > .tooltip-arrow {
    border-bottom-color: @state-danger-text;
  }

  .left > .tooltip-arrow {
    border-left-color: @state-danger-text;
  }

  .right > .tooltip-arrow {
    border-right-color: @state-danger-text;
  }
}

// Warning
.warning-tooltip {
  .tooltip-inner {
    color: @whiteColor;
    background-color: @state-warning-text;
  }

  .top > .tooltip-arrow {
    border-top-color: @state-warning-text;
  }

  .bottom > .tooltip-arrow {
    border-bottom-color: @state-warning-text;
  }

  .left > .tooltip-arrow {
    border-left-color: @state-warning-text;
  }

  .right > .tooltip-arrow {
    border-right-color: @state-warning-text;
  }
}

// Info
.info-tooltip {
  .tooltip-inner {
    color: @whiteColor;
    background-color: @state-info-text;
  }

  .top > .tooltip-arrow {
    border-top-color: @state-info-text;
  }

  .bottom > .tooltip-arrow {
    border-bottom-color: @state-info-text;
  }

  .left > .tooltip-arrow {
    border-left-color: @state-info-text;
  }

  .right > .tooltip-arrow {
    border-right-color: @state-info-text;
  }
}

// success
.success-tooltip {
  .tooltip-inner {
    color: @whiteColor;
    background-color: @state-success-text;
  }

  .top > .tooltip-arrow {
    border-top-color: @state-success-text;
  }

  .bottom > .tooltip-arrow {
    border-bottom-color: @state-success-text;
  }

  .left > .tooltip-arrow {
    border-left-color: @state-success-text;
  }

  .right > .tooltip-arrow {
    border-right-color: @state-success-text;
  }
}

.field-left {
  display: inline-block;
  width: ~"calc(100% - 100px)";
  vertical-align: top;
}

.field-right {
  width: 30px;
  height: 30px;
  vertical-align: top;
  margin-left: 10px;
}

.field-right-header {
  float: right;
  margin-right: 20px;
}

//.common-characteristics .field-required{
//  display: none;
//}

.field-required {
  color: red;
}

.field-optional {
  color: @darkGreyColor;
  font-size: 80%;
  font-style: italic;
}

.view-header-details {
  width: ~"calc(100% - 250px)";
  display: inline-block;
  text-align: center;
}

.margin-right-x10 {
  padding-right: 20px;
}

// Easter egg
// ----------
@tankHeight: 200px;
@tankWidth: 300px;
@bulletHeight: 50px;
@bulletWidth: 80px;

@-webkit-keyframes ee-tank-move {
  0% {
    left: -@tankWidth;
  }
  100% {
    left: 100%;
  }
}

@keyframes ee-tank-move {
  0% {
    left: -@tankWidth;
  }
  100% {
    left: 100%;
  }
}

/*#ee-tank {
  .t {
    position: absolute;
    background: url('../images/tank.png') no-repeat center;
    background-size: contain;
    width: @tankWidth;
    height: @tankHeight;
    z-index: 1;
  }
  position: absolute;
  z-index: 5001;
  top: 50%;
  left: -@tankWidth;
  width: @tankWidth;
  height: @tankHeight;
  margin-top: (-@tankHeight / 2);
  -webkit-animation: ee-tank-move 15s linear 1s 1;
  animation: ee-tank-move 15s linear 1s 1;
}*/

@-webkit-keyframes ee-bullet-move {
  0% {
    left: (@tankWidth / 2);
  }
  100% {
    left: 2000px;
  }
}

@keyframes ee-bullet-move {
  0% {
    left: (@tankWidth / 2);
  }
  100% {
    left: 2000px;
  }
}

/*.ee-bullet {
  background: url('../images/bullet.png') no-repeat center;
  background-size: contain;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: (@tankWidth / 2);
  width: @bulletWidth;
  height: @bulletHeight;
  margin-top: (-@bulletHeight / 2);
  -webkit-animation: ee-bullet-move 2s linear 0 1;
  animation: ee-bullet-move 2s linear 0 1;
}*/

@-webkit-keyframes ee-wobble {
  0% {
    -webkit-transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-1.5%) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(1.2%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-.9%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(.6%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-.3%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes ee-wobble {
  0% {
    transform: translateX(0%);
  }
  15% {
    transform: translateX(-1.5%) rotate(-5deg);
  }
  30% {
    transform: translateX(1.2%) rotate(3deg);
  }
  45% {
    transform: translateX(-.9%) rotate(-3deg);
  }
  60% {
    transform: translateX(.6%) rotate(2deg);
  }
  75% {
    transform: translateX(-.3%) rotate(-1deg);
  }
  100% {
    transform: translateX(0%);
  }
}

.ee-wobble {
  -webkit-transition: all 0.8s ease-in-out;
  -webkit-animation: ee-wobble .4s linear 1s infinite;
  -webkit-animation-fill-mode: both;
  transition: all 0.8s ease-in-out;
  animation: ee-wobble .4s linear 1s infinite;
  animation-fill-mode: both;
}

.ee-psy {
  -webkit-animation: ee-psy 3s infinite;
  animation: ee-psy 3s infinite;
}

@-webkit-keyframes ee-psy {
  0%, 100% {
    -webkit-filter: hue-rotate(0deg);
  }
  50% {
    -webkit-filter: hue-rotate(180deg);
  }
}

@keyframes ee-psy {
  0%, 100% {
    filter: hue-rotate(0deg);
  }
  50% {
    filter: hue-rotate(180deg);
  }
}

.ee-blur {
  -webkit-animation: ee-blur 1s infinite;
  animation: ee-blur 1s infinite;
}

@-webkit-keyframes ee-blur {
  0%, 100% {
    -webkit-filter: blur(0px);
  }
  50% {
    -webkit-filter: blur(10px);
  }
}

@keyframes ee-blur {
  0%, 100% {
    filter: blur(0px);
  }
  50% {
    filter: blur(10px);
  }
}
