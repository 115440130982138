@pulseBackgroungColor: rgba(218, 219, 219, .3);

.pulse {
  box-shadow: 0 0 0 @pulseBackgroungColor inset;
  animation: pulse 2s infinite;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 @pulseBackgroungColor inset;
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px @pulseBackgroungColor inset;
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 @pulseBackgroungColor inset;
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 @pulseBackgroungColor inset;
    box-shadow: 0 0 0 0 @pulseBackgroungColor inset;
  }
  70% {
    -moz-box-shadow: 0 0 0 10px @pulseBackgroungColor inset;
    box-shadow: 0 0 0 10px @pulseBackgroungColor inset;
  }
  100% {
    -moz-box-shadow: 0 0 0 0 @pulseBackgroungColor inset;
    box-shadow: 0 0 0 0 @pulseBackgroungColor inset;
  }
}

@-webkit-keyframes textPulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.04, 1.04, 1.04);
    transform: scale3d(1.04, 1.04, 1.04);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes textPulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.04, 1.04, 1.04);
    transform: scale3d(1.04, 1.04, 1.04);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.textPulse {
  -webkit-animation: pulse 2s infinite;
  animation: textPulse 2s infinite;
}
