// ========================
// Shared Application Style
// ========================
@import "se-theme.less";
@import "se-variables.less";
@import "app-variables.less";
@import "backoffice-datatable.less";
@import "../../../node_modules/bootstrap/less/mixins.less";
@import "animation.less";

// Global style
// -------------
html, body {
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;

  // for a more 'native' look on mobile devices
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-scroll-chaining: none;
  -ms-touch-action: none;
}

html {
  min-width: @appMinWidth;
  min-height: @appMinHeight;
  background: @appBackgroundColor;
  overflow: auto;
}

body {
  font-size: @appTextFontSize;
  font-family: @appTextFontFamily;
  background: transparent;
}
.p-component {
  font-size: inherit;
}
.p-inputtext {
  font-size: inherit;
}

::selection {
  background-color: @appSelectionColor;
}

div, span {
  cursor: default;

  a &, button &, .button & {
    cursor: inherit;
  }
}
a {
  color: #7B95A3;
}

a:hover {
  color: #7B95A3;
}

a, button, .button {
  cursor: pointer;
}

// Simple helper classes
// ---------------------
// Spacing
.space-left {
  margin-left: @appSmallMargin;
}

.space-right {
  margin-right: @appSmallMargin;
}

.space-top {
  margin-top: @appSmallMargin;
}

.space-bottom {
  margin-bottom: @appSmallMargin;
}

.content-wrapper {
  margin: @appContentMargin;
}

// Content sizing
.fill {
  width: 100%;
  height: 100%;
}

.vertical-fill {
  height: 100%;
}

.horizontal-fill {
  width: 100%;
}

.scrollable {
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0;
}

.relative {
  position: relative;
}

// Typography
.text-uppercase {
  text-transform: uppercase;
}

/*.text-overflow {
  // Enable ellipsis on text overflow, must be used on block type elements
  .text-overflow();
}*/

.bold {
  font-weight: bold;
}

.force-small {
  // Use only to force font size override, use .small in other cases
  font-size: 85% !important;
}

.text-title {
  font-family: @appTitleFontFamily;
}

// Vertical centering (aka, CSS is a bitch)
.vertical-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

// Content display
.block {
  display: block;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

// App Splash screen
// -----------------
.app-splash {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 4000;
  background-color: @appSplashTextColor;
  overflow: hidden;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    position: relative;
  }
  .shadow {
    -moz-box-shadow: rgba(0, 0, 0, 0.5) 0 -70px 50px 0 inset;
    -webkit-box-shadow: rgba(0, 0, 0, 0.5) 0 -70px 50px 0 inset;
    box-shadow: rgba(0, 0, 0, 0.5) 0 -70px 50px 0 inset;
  }
  .splash-container {
    height: 100%;
    width: 100%;
  }
  .background {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    z-index: -1;
    position: relative;
    width: 100%;
    height: 100%;
    background: url("../images/splashscreen.jpg");
    background-size: cover;
  }
  .transparent-div {
    background-color: rgba(61, 205, 88, 0.5);
    height: 40px;
    position: absolute;
    bottom: 266px;
    width: 100%;
  }
  .loading-bar {
    height: 8px;
    background-color: transparent;
    border-radius: 0;
    border: 0;
    position: absolute;
    bottom: 0;
    &.progress, .progress-bar {
      border-radius: 0;
    }
    > bar {
      background: @seWhiteColor;
      position: relative;
    }
  }
  .menu {
    background-color: @seOfficialLightGreenColor;
    height: 164px;
    position: absolute;
    bottom: 102px;
    width: 100%;
    .logo {
      width: 147px;
      height: 27px;
      position: absolute;
      margin-top: 32px;
      margin-left: 64px;
    }
    .app-name {
      font: 40px Nunito;
      // We use nunito semi-bold
      font-weight: 600;
      color: @appSplashTextColor;
      margin: 62px 384px 0 60px;
      line-height: 48px;
      text-align: left;
      .font-sub {
        // we use nunito light
        font-weight: 300;
      }
    }
    .version {
      font: 17px Nunito;
      // we use nunito extra light
      font-weight: 200;
      color: @appSplashTextColor;
      width: 100%;
      margin: 0 450px 26px 64px;
      line-height: 19px;
      text-align: left;
    }
    .loading-resources{
      font: @font-size-small Nunito;
      // we use nunito extra light
      font-weight: 200;
      color: @appSplashTextColor;
      margin-right: 24px;
      margin-top: 12px;
      float: right;
    }
  }
  .splash-footer {
    bottom: 0;
    width: 100%;
    position: absolute;
    height: 102px;
    .footer-message {
      font: @font-size-small Nunito;
      // we use nunito extra light
      font-weight: 200;
      margin-left:60px;
      margin-top: 38px;
      color: @appSplashTextColor;
    }
    .footer-logo {
      right: 24px;
      bottom: 24px;
      position: absolute;
    }
  }
}

.div-app {
  height: 100%;
}
// Not supported message for outdated browsers
// -------------------------------------------
.app-not-supported {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 5000;
  background-color: @appContentBackgroundColor;
}

.app-not-supported-box {
  position: relative;
  top: 30%;
  background: @appBackgroundColor;
  width: 500px;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
  border: 1px solid @appBorderColor;
}

.app-warn-icon {
  background: url('../images/warn.png') center center no-repeat;
  height: 22px;
  width: 22px;
}

// FIX for big modals
.modal-wide {
  .modal-body {
    .modal-fixed-height {
      top: @appBigMargin;
    }
  }
}

.modal {
  .modal-content {
    overflow: inherit;
  }
}

/* Helper to disable link buttons */
a.link-disabled {
  cursor: pointer;
  pointer-events: none;
  color: @lightGreyColor !important;
}

/* Global style because it isn't possible to inject component style in nested component */
.bom-table td:nth-of-type(4), .bom-table td:nth-of-type(6) {
  text-align: right;
}

.bom-table td:nth-of-type(5) {
  text-align: center;
}

.bomPrice {
  font-weight: bold;
}

.bomHeader div div table .p-treetable-thead > tr > th span {
  .text-success;
  white-space: normal;
}

.bomHeader table .p-treetable-tbody > tr > td, .bomHeader table .p-treetable-thead > tr > th{
  border: none;
}

.bomHeader div div table .p-treetable-thead > tr > th {
  border-bottom: 2px solid #cbcbcb;
}

.bomHeader div div table > tbody > .p-treetable-row {
  height: 40px;
  vertical-align: middle;
}

.bomHeader div div table .p-treetable-tbody > tr > td{
  border-top: 1px solid #cbcbcb;
}

.bomHeader table .p-treetable-tbody span {
  color: @anthraciteGreyColor;
}
.bom-tooltip-discount {
  width: 400px;
  text-align: center;
}

.bom-text-overflow{
  text-overflow: ellipsis;
  &:hover{
    overflow: visible;
  }
}

.p-treetable td.bom-category{
  overflow: visible;
  white-space: nowrap ;
}

.bom-break-word{
  word-wrap: break-word;
}

.bomHeader .p-treetable .p-treetable-thead th{
  background-color: @veryLightGrey;
}

.p-treetable th {
  &.text-right{text-align: right}
  &.text-center{text-align: center}
  &.text-left{text-align: left}
}

.p-treetable tbody td, .p-treetable tfoot td {
  white-space: normal;
}

/* */
.my-dropdown > button {
  width: 100%;
}

.my-dropdown > button > span {
  position: absolute;
  top: 15px;
  right: 10px;
}

.my-dropdown .dropdown-menu > div > a {
  white-space: normal;
}

.my-dropdown .btn-default.dropdown-toggle,
.my-dropdown .fa-check {
  color: @spruceGreenColor;
}

.my-dropdown .fa-times {
  color: @redColor;
}

/* Global style because it isn't possible to inject component style in nested component */

tabset .nav-pills {
  background-color: @tabsetBackgroundColor;
  font-size: 16px;
  color: inherit;
}

tabset .nav-pills > li > a {
  border-radius: 0;
  color: inherit;
  //font-weight: bold   ;
  //color: @anthraciteGreyColor;
  background-color: @tabsetBackgroundColor;
  margin: 0;
}

tabset .nav-pills > li.active > a, tabset .nav-pills > li.active > a:hover, tabset .nav-pills > li.active > a:focus {
  color: inherit;
  //font-weight: bold   ;
  //color: @anthraciteGreyColor;
  background-color: @whiteColor;
}

/* Global style because it isn't possible to inject component style in nested component */
.doc-table {
  text-align: left;
  thead {
    visibility: hidden;
  }
  tr:hover {
    background-color: @anthraciteGreyColor !important;
    color: @whiteColor !important;
    cursor: pointer;
  }
  tr:first-child {
    border: none;
  }
  td:first-child {
    color: @spruceGreenColor;
  }
}

.tooltip .tooltip-inner {
  background-color: @seOfficialBlueColor;
  font-size: 14px;
  max-width: 500px;
}

.tooltip-right .tooltip-arrow {
  border-right-color: @seOfficialBlueColor !important;
}

.tooltip-left .tooltip-arrow {
  border-left-color: @seOfficialBlueColor !important;
}

.tooltip-top .tooltip-arrow {
  border-top-color: @seOfficialBlueColor !important;
}

.tooltip-bottom .tooltip-arrow {
  border-bottom-color: @seOfficialBlueColor !important;
}

/*
  Prime-ng panel customizations
 */
.express-panel-design .p-panel .p-panel-content {
  background: @ultraLightGrey1Color;
}

.express-panel-design .p-panel .p-panel-titlebar{
  padding: .5em .75em;
  font-weight: bold;
  background: @ultraLightGrey1Color;
  color: @anthraciteGreyColor;
  border-width: 0 0  1px 0;
  border-color: @borderLineTableGrey;
}

.express-panel-design .p-panel .p-panel-titlebar .p-panel-titlebar-icon{
  background: @ultraLightGrey1Color;
  color: @anthraciteGreyColor;
}

/* Global style because it isn't possible to inject component style in nested component */
.characteristicsFieldset {
  .ct-multiselect {
    .ng-star-inserted{
      outline: none!important;
    }
    button {
      color: @superDarkGreyColor;
      background-color: @whiteColor;
      border: 1px solid @lightGreyColor;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 30px;
      &.dropdown-toggle {
        color: @superDarkGreyColor;
        background-color: @whiteColor;
      }
      span {
        top: 10px;
        right: 7px;
        &.caret {
          border-top-width: 6px;
          border-right-width: 3px;
          border-left-width: 3px;
        }
      }
    }
    div.dropdown-menu {
      width: 100%;
    }
    .dropdown-item{
      display: list-item;
    }
    &.open {
      background-color: @whiteColor;
    }
  }
}
/* Global style because it isn't possible to inject component style in nested component */
.error-indicator-container.tooltip-bottom {

  .tooltip-arrow {
    border-top-color: @seRedColor !important;
    border-bottom-color: @seRedColor !important;
  }
  .tooltip-inner {
    background-color: @seRedColor;
    font-size: 14px;
    min-width: @minimumWidthTooltip;
  }
}
/* Global style because it isn't possible to inject component style in nested component */
.cubicle-transformer-warning-indicator-container.tooltip-bottom {

  .tooltip-arrow {
    border-top-color: @warningColor !important;
    border-bottom-color: @warningColor !important;
  }
  .tooltip-inner {
    background-color: @warningColor;
    font-size: 14px;
    min-width: @minimumWidthTooltip;
  }
}

.p-autocomplete-input {
  border: 1px solid @lightGreyColor;
  width: 100%;
}

.font-param {
  font-size: @fontSizeSEIcons;
}

.selectable {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
