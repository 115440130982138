// ============================
// Shell app-specific additions
// ============================
@import "./app-variables.less";

// App header
// ----------
.app-header-container {
  line-height: @appHeaderHeight;
}

.app-header-partner {
  height: 40px;
  max-width: 200px;
  margin-top: -3px;
  border-radius: 5px;
}

.app-header-badge {
  position: absolute;
  bottom: -9px;
  right: -4px;
  min-width: 24px;
  font-size: 11px;
  padding: 3px 5px;
  line-height: 1;
  white-space: nowrap;
  border-radius: 9px;
  background-color: @warmRedColor;
  border: 1px solid @whiteColor;
  color: @whiteColor;
  text-align: center;
  background-clip: padding-box;
}








// App error
// ---------
@appErrorMessageBoxHeight: 42px;
@appErrorMessageBoxWidth: 400px;

.app-error {
  position: absolute;
  z-index: 3000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: fade(@trueBlackColor, 30%);
}

.app-error-message {
  position: absolute;
  width: @appErrorMessageBoxWidth;
  height: @appErrorMessageBoxHeight;
  top: 50%;
  left: 50%;
  margin-top: (-@appErrorMessageBoxHeight / 2);
  margin-left: (-@appErrorMessageBoxWidth / 2);
}


// Provide feedback
// ---------
.atlwdg-RIGHT {
  margin-top: 15%;
}



