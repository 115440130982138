// =================
// Application shell
// =================
@import "./../../../node_modules/bootstrap/less/mixins.less";
@import "./app-base.less";

// App header & footer
// -------------------
@seProjectBarHeight: 65px;
.app-header {
  position: absolute;
  z-index: 100;
  width: 100%;

  // Override default navbar
  > .navbar {
    border: 0;
    font-size: @appTextEmphasisFontSize;
    margin-bottom: 0;
  }

  // Override responsiveness
  .nav > li {
    float: left;
  }

  .navbar-form {
    width: auto;
    border: 0;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    .box-shadow(none);
  }
}

.app-brand {
  float: left;
  margin-left: @appMediumMargin;

  &:hover, &:focus {
    text-decoration: none;
  }
}

.app-title {
  float: left;
  font-family: @appTitleFontFamily;
  font-size: 24px;
  margin-right: 20px;
  text-transform: initial;
  text-decoration: none;
  color: @appTitleColor;
  margin-left: @appMediumMargin;
  line-height: @appHeaderHeight;
}

.app-logo {
  height: @appLogoHeight;
  margin-bottom: ((@seProjectBarHeight - @appLogoHeight) / 2);
  margin-left: @appBigMargin;
  cursor: pointer;
}

.app-header-logo {
  vertical-align: middle;
  height: @appLogoHeight;
  margin-right: @appMediumMargin;
}

.app-header-separator {
  border-left: 1px solid @darkGreyColor;
  height: (@appHeaderHeight - @appSmallMargin * 2);
  margin: @appSmallMargin @appMediumMargin 0 @appMediumMargin;
}

.app-header-icon {
  font-size: 18px;
}

.app-header-panel {
  margin: 0 @appMediumMargin;
}

.app-header-panel-row + .app-header-panel-row {
  margin-top: 3px;
}

.app-header-locale {
  font-size: @appTextFontSize;
  margin-top: 6px;
  line-height: 1.5;
}

// App main
// --------
.app-main {
  height: 100%;
}

// App click blocker
// ------------------
.app-click-blocker {
  position: absolute;
  top: 0;
  z-index: 1001;
  height: 100%;
  width: 100%;
  background: url('../images/transparent.png') no-repeat;
}

// App content
// -----------
.app-content {
  position: relative;
  .fill;
}


.height-max
{
  height: 100%;
}
// Include app-specific additions for the shell
@import "shell-additions.less";


