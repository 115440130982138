// ============================
// SE theme Bootstrap variables
// ============================
//
// Variables
// --------------------------------------------------
// Global values
// --------------------------------------------------
// Grays
// -------------------------
// SE theme variables
@import "se-variables";

@gray-darker: @anthraciteGreyColor;
@gray-dark: @superDarkGreyColor;
@gray: @darkGreyColor;
@gray-light: @lightGreyColor;
@gray-lighter: @ultraLightGrey1Color; // @superLightGreyColor;

// Brand colors
// -------------------------

@brand-primary: @spruceGreenColor;
@brand-success: @seOfficialLightGreenColor;
@brand-warning: @honeysuckleOrangeColor;
@brand-danger: @redColor;
@brand-info: @skyBlueColor;

// Scaffolding
// -------------------------

@body-bg: @whiteColor;
@text-color: @gray-darker;

// Links
// -------------------------

@link-color: @brand-info; //@brand-primary;
@link-hover-color: @skyBlueColor; //darken(@link-color, 15%);

// Typography
// -------------------------

@font-family-sans-serif: @textFontFamily;
@font-family-serif: Georgia, "Times New Roman", Times, serif;
@font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-base: @font-family-sans-serif;

@font-size-base: @textContentFontSize;
@font-size-large: 18px; //ceil(@font-size-base * 1.25); // ~18px
@font-size-small: 12px; //ceil(@font-size-base * 0.85); // ~12px

@font-size-h1: 26px; //floor(@font-size-base * 2.6); // ~36px
@font-size-h2: 20px; //floor(@font-size-base * 2.15); // ~30px
@font-size-h3: 20px; //ceil(@font-size-base * 1.7); // ~24px
@font-size-h4: 18px; //ceil(@font-size-base * 1.25); // ~18px
@font-size-h5: 16px;
@font-size-h6: 14px; //ceil(@font-size-base * 0.85); // ~12px

@line-height-base: 1.428571429; // 20/14
@line-height-computed: 20px; //floor(@font-size-base * @line-height-base); // ~20px

@headings-font-family: @titleFontFamily;
@headings-font-weight: 500;
@headings-line-height: 1.1;
@headings-color: @spruceGreenColor; //@gray-dark; //inherit;

// Iconography
// -------------------------

@icon-font-path: "../node_modules/bootstrap/fonts/";
@icon-font-name: "glyphicons-halflings-regular";

// Components
// -------------------------
// Based on 14px font-size and 1.428 line-height (~20px to start)

@padding-base-vertical: 4px;
@padding-base-horizontal: 10px;

@padding-large-vertical: 10px;
@padding-large-horizontal: 16px;

@padding-small-vertical: 2px;
@padding-small-horizontal: 10px;

@padding-xs-vertical: 1px;
@padding-xs-horizontal: 5px;

@line-height-large: 1.33;
@line-height-small: 1.5;

@border-radius-base: 2px; //4px;
@border-radius-large: 4px; //6px;
@border-radius-small: 2px; //4px;

@component-active-color: @whiteColor;
@component-active-bg: @brand-primary;

@caret-width-base: 4px;
@caret-width-large: 5px;

// Tables
// -------------------------

@table-cell-padding: 8px;
@table-condensed-cell-padding: 5px;

@table-bg: transparent; // overall background-color
@table-bg-accent: @ultraLightGrey2Color; // for striping
@table-bg-hover: @gray-lighter;
@table-bg-active: @table-bg-hover;

@table-border-color: @lightGreyColor; // table and cell border

// Buttons
// -------------------------

@btn-font-weight: normal;

@btn-default-color: @superDarkGreyColor;
@btn-default-bg: @whiteColor;
@btn-default-border: @lightGreyColor;

@btn-primary-color: @whiteColor;
@btn-primary-bg: @brand-primary;
@btn-primary-border: darken(@btn-primary-bg, 2%);

@btn-success-color: @whiteColor;
@btn-success-bg: @brand-success;
@btn-success-border: darken(@btn-success-bg, 2%);

@btn-warning-color: @whiteColor;
@btn-warning-bg: @brand-warning;
@btn-warning-border: darken(@btn-warning-bg, 2%);

@btn-danger-color: @whiteColor;
@btn-danger-bg: @brand-danger;
@btn-danger-border: darken(@btn-danger-bg, 2%);

@btn-info-color: @seBlackColor;
@btn-info-bg: @brand-info;
@btn-info-border: darken(@btn-info-bg, 2%);

@btn-validate-color: @whiteColor;
@btn-validate-bg: @brand-primary;
@btn-validate-border: darken(@btn-validate-bg, 2%);
@btn-validate-disable-color: @mediumGreyColor;
@btn-validate-disable-bg:  @superLightGreyColor;
@btn-validate-disable-border: darken(@btn-validate-disable-bg, 2%);

@btn-link-disabled-color: @gray-light;

// Forms
// -------------------------

@input-bg: @whiteColor;
@input-bg-disabled: @gray-lighter;

@input-color: @gray;
@input-border: @lightGreyColor;
@input-border-radius: @border-radius-base;
@input-border-focus: @brand-info; //@spruceGreenColor;

@input-color-placeholder: @gray-light;

@input-height-base: (@line-height-computed + (@padding-base-vertical * 2) + 2);
@input-height-large: (ceil(@font-size-large * @line-height-large) + (@padding-large-vertical * 2) + 2);
@input-height-small: ((@font-size-small * @line-height-small) + (@padding-small-vertical * 2) + 2);

@legend-color: @gray-dark;
@legend-border-color: @gray-lighter;

@input-group-addon-bg: @ultraLightGrey1Color; //@gray-lighter;
@input-group-addon-border-color: @input-border;

// Dropdowns
// -------------------------

@dropdown-bg: @whiteColor;
@dropdown-border: rgba(0, 0, 0, .15);
@dropdown-fallback-border: @lightGreyColor;
@dropdown-divider-bg: @gray-lighter;

@dropdown-link-color: @gray-dark;
@dropdown-link-hover-color: @brand-info; //darken(@gray-dark, 5%);
@dropdown-link-hover-bg: @whiteColor;

@dropdown-link-active-color: @component-active-color;
@dropdown-link-active-bg: @component-active-bg;

@dropdown-link-disabled-color: @gray-light;

@dropdown-header-color: @gray-light;

// COMPONENT VARIABLES
// --------------------------------------------------

// Z-index master list
// -------------------------
// Used for a bird's eye view of components dependent on the z-axis
// Try to avoid customizing these :)

@zindex-navbar: 1000;
@zindex-dropdown: 1000;
@zindex-popover: 1010;
@zindex-tooltip: 1030;
@zindex-navbar-fixed: 1030;
@zindex-modal-background: 1040;
@zindex-modal: 1050;

// Media queries breakpoints
// --------------------------------------------------

// Extra small screen / phone
// Note: Deprecated @screen-xs and @screen-phone as of v3.0.1
@screen-xs: 0; // disable phone responsiveness
@screen-xs-min: @screen-xs;
@screen-phone: @screen-xs-min;

// Small screen / tablet
// Note: Deprecated @screen-sm and @screen-tablet as of v3.0.1
@screen-sm: 768px;
@screen-sm-min: @screen-sm;
@screen-tablet: @screen-sm-min;

// Medium screen / desktop
// Note: Deprecated @screen-md and @screen-desktop as of v3.0.1
@screen-md: 992px;
@screen-md-min: @screen-md;
@screen-desktop: @screen-md-min;

// Large screen / wide desktop
// Note: Deprecated @screen-lg and @screen-lg-desktop as of v3.0.1
@screen-lg: 1200px;
@screen-lg-min: @screen-lg;
@screen-lg-desktop: @screen-lg-min;

// So media queries don't overlap when required, provide a maximum
@screen-xs-max: (@screen-sm-min - 1);
@screen-sm-max: (@screen-md-min - 1);
@screen-md-max: (@screen-lg-min - 1);

// Grid system
// --------------------------------------------------

// Number of columns in the grid system
@grid-columns: 12;
// Padding, to be divided by two and applied to the left and right of all columns
@grid-gutter-width: 20px;

// Navbar collapse

// Point at which the navbar becomes uncollapsed
@grid-float-breakpoint: @screen-sm-min;
// Point at which the navbar begins collapsing
@grid-float-breakpoint-max: (@grid-float-breakpoint - 1);

// Navbar
// -------------------------

// Basics of a navbar
@navbar-height: 80px;
@navbar-margin-bottom: @line-height-computed;
@navbar-border-radius: 0;
@navbar-padding-horizontal: (@grid-gutter-width / 2);
@navbar-padding-vertical: ((@navbar-height - @line-height-computed) / 2);

@navbar-default-color: @whiteColor; //@gray-light
@navbar-default-bg: @anthraciteGreyColor;
@navbar-default-border: @anthraciteGreyColor; //darken(@navbar-default-bg, 6.5%);

// Navbar links
@navbar-default-link-color: @spruceGreenColor; // @darkGreyColor;
@navbar-default-link-hover-color: @whiteColor; //@ladyFernGreenColor;
@navbar-default-link-hover-bg: transparent;
@navbar-default-link-active-color: @seedingGreenColor; //@gray;
@navbar-default-link-active-bg: darken(@navbar-default-bg, 6.5%);
@navbar-default-link-disabled-color: @darkGreyColor; //@lightGreyColor;
@navbar-default-link-disabled-bg: transparent;

// Navbar brand label
@navbar-default-brand-color: @navbar-default-link-color;
@navbar-default-brand-hover-color: darken(@navbar-default-brand-color, 10%);
@navbar-default-brand-hover-bg: transparent;

// Navbar toggle
@navbar-default-toggle-hover-bg: @gray-lighter;
@navbar-default-toggle-icon-bar-bg: @lightGreyColor;
@navbar-default-toggle-border-color: @gray-lighter;

// Inverted navbar
//
// Reset inverted navbar basics
@navbar-inverse-color: @darkGreyColor;
@navbar-inverse-bg: @whiteColor;
@navbar-inverse-border: @whiteColor; //darken(@navbar-inverse-bg, 10%);

// Inverted navbar links
@navbar-inverse-link-color: @gray-light;
@navbar-inverse-link-hover-color: @whiteColor;
@navbar-inverse-link-hover-bg: transparent;
@navbar-inverse-link-active-color: @navbar-inverse-link-hover-color;
@navbar-inverse-link-active-bg: darken(@navbar-inverse-bg, 10%);
@navbar-inverse-link-disabled-color: @ultraDarkGreyColor;
@navbar-inverse-link-disabled-bg: transparent;

// Inverted navbar brand label
@navbar-inverse-brand-color: @navbar-inverse-link-color;
@navbar-inverse-brand-hover-color: @whiteColor;
@navbar-inverse-brand-hover-bg: transparent;

// Inverted navbar toggle
@navbar-inverse-toggle-hover-bg: @anthraciteGreyColor;
@navbar-inverse-toggle-icon-bar-bg: @whiteColor;
@navbar-inverse-toggle-border-color: @anthraciteGreyColor;

// Navs
// -------------------------

@nav-link-padding: 10px 10px;
@nav-link-hover-bg: @gray-lighter;

@nav-disabled-link-color: @gray-light;
@nav-disabled-link-hover-color: @gray-light;

@nav-open-link-hover-color: @whiteColor;

// Tabs
@nav-tabs-border-color: @gray-lighter;

@nav-tabs-link-hover-border-color: @gray-lighter;

@nav-tabs-active-link-hover-bg: @body-bg;
@nav-tabs-active-link-hover-color: @gray;
@nav-tabs-active-link-hover-border-color: @gray-lighter;

@nav-tabs-justified-link-border-color: @gray-lighter;
@nav-tabs-justified-active-link-border-color: @body-bg;

// Pills (TODO ETR  used for ecorealct  because defining a css class on tabset does not work)
@tabsetBackgroundColor : #EDEDED ;
@nav-pills-border-radius: @border-radius-base;
@nav-pills-active-link-hover-bg:  @tabsetBackgroundColor ; //@component-active-bg ;
@nav-pills-active-link-hover-color: @anthraciteGreyColor ; //@component-active-color;


// Pagination
// -------------------------

@pagination-bg: @whiteColor;
@pagination-border: @gray-lighter;

@pagination-hover-bg: @gray-lighter;

@pagination-active-bg: @brand-primary;
@pagination-active-color: @whiteColor;

@pagination-disabled-color: @gray-light;

// Pager
// -------------------------

@pager-border-radius: 15px;
@pager-disabled-color: @gray-light;

// Jumbotron
// -------------------------

@jumbotron-padding: 20px;
@jumbotron-color: inherit;
@jumbotron-bg: @gray-lighter;
@jumbotron-heading-color: inherit;
@jumbotron-font-size: ceil(@font-size-base * 1.5);

// Form states and alerts
// -------------------------

@state-success-text: @brand-success;
@state-success-bg: mix(@brand-success, #fff, 5%); //@lightGreen10pColor;
@state-success-border: @brand-success;

@state-info-text: @brand-info;
@state-info-bg: mix(@brand-info, #fff, 5%); //@skyBlue10pColor;
@state-info-border: @brand-info;

@state-warning-text: @brand-warning;
@state-warning-bg: mix(@brand-warning, #fff, 5%); //@honeysuckleOrange10pColor;
@state-warning-border: @brand-warning;

@state-danger-text: @brand-danger;
@state-danger-bg: mix(@brand-danger, #fff, 5%); //@red10pColor;
@state-danger-border: @brand-danger;

// Tooltips
// -------------------------
@tooltip-max-width: 400px;
@tooltip-color: @whiteColor;
@tooltip-bg: @brand-info;

@tooltip-arrow-width: 5px;
@tooltip-arrow-color: @tooltip-bg;

// Popovers
// -------------------------
@popover-bg: @whiteColor;
@popover-max-width: 400px;
@popover-border-color: @lightGreyColor; //rgba(0,0,0,.2);
@popover-fallback-border-color: @lightGreyColor;

@popover-title-bg: @ultraLightGrey1Color; //darken(@popover-bg, 3%);

@popover-arrow-width: 10px;
@popover-arrow-color: @whiteColor;

@popover-arrow-outer-width: (@popover-arrow-width + 1);
@popover-arrow-outer-color: @lightGreyColor; //rgba(0,0,0,.25);
@popover-arrow-outer-fallback-color: @lightGreyColor;

// Labels
// -------------------------

@label-default-bg: @gray-light;
@label-primary-bg: @brand-primary;
@label-success-bg: @brand-success;
@label-info-bg: @brand-info;
@label-warning-bg: @brand-warning;
@label-danger-bg: @brand-danger;

@label-color: @whiteColor;
@label-link-hover-color: @whiteColor;

// Modals
// -------------------------
@modal-inner-padding: 20px;

@modal-title-padding: 10px;
@modal-title-line-height: @line-height-base;

@modal-content-bg: @whiteColor;
@modal-content-border-color: rgba(0, 0, 0, .2);
@modal-content-fallback-border-color: @mediumGreyColor;

@modal-backdrop-bg: @trueBlackColor;
@modal-header-border-color: @gray-lighter;
@modal-footer-border-color: @modal-header-border-color;

// Alerts
// -------------------------
@alert-padding: 10px;
@alert-border-radius: @border-radius-base;
@alert-link-font-weight: bold;

@alert-success-bg: @state-success-bg;
@alert-success-text: @state-success-text;
@alert-success-border: @state-success-border;

@alert-info-bg: @state-info-bg;
@alert-info-text: @state-info-text;
@alert-info-border: @state-info-border;

@alert-warning-bg: @state-warning-bg;
@alert-warning-text: @state-warning-text;
@alert-warning-border: @state-warning-border;

@alert-danger-bg: @state-danger-bg;
@alert-danger-text: @state-danger-text;
@alert-danger-border: @state-danger-border;

// Progress bars
// -------------------------
@progress-bg: rgba(0, 0, 0, .24);
@progress-bar-color: @whiteColor;

@progress-bar-bg: @brand-primary;
@progress-bar-success-bg: @seedingGreenColor; //@brand-success;
@progress-bar-warning-bg: @brand-warning;
@progress-bar-danger-bg: @brand-danger;
@progress-bar-info-bg: @brand-info;

// List group
// -------------------------
@list-group-bg: @whiteColor;
@list-group-border: @lightGreyColor;
@list-group-border-radius: @border-radius-base;

@list-group-hover-bg: @ultraLightGrey2Color;
@list-group-active-color: @component-active-color;
@list-group-active-bg: @component-active-bg;
@list-group-active-border: @list-group-active-bg;

@list-group-link-color: @superDarkGreyColor;
@list-group-link-heading-color: @anthraciteGreyColor;

// Panels
// -------------------------
@panel-bg: @whiteColor;
@panel-inner-border: @gray-lighter; //@lightGreyColor;
@panel-border-radius: @border-radius-base;
@panel-footer-bg: @ultraLightGrey2Color;

@panel-default-text: @gray-dark;
@panel-default-border: @gray-lighter; //@lightGreyColor;
@panel-default-heading-bg: @ultraLightGrey2Color;

@panel-primary-text: @whiteColor;
@panel-primary-border: @brand-primary;
@panel-primary-heading-bg: @brand-primary;

@panel-success-text: @whiteColor;
@panel-success-border: @state-success-border;
@panel-success-heading-bg: @state-success-border;

@panel-warning-text: @whiteColor;
@panel-warning-border: @state-warning-border;
@panel-warning-heading-bg: @state-warning-border;

@panel-danger-text: @whiteColor;
@panel-danger-border: @state-danger-border;
@panel-danger-heading-bg: @state-danger-border;

@panel-info-text: @whiteColor;
@panel-info-border: @state-info-text;
@panel-info-heading-bg: @state-info-text;

// Thumbnails
// -------------------------
@thumbnail-padding: 4px;
@thumbnail-bg: @body-bg;
@thumbnail-border: @gray-lighter;
@thumbnail-border-radius: @border-radius-base;

@thumbnail-caption-color: @text-color;
@thumbnail-caption-padding: 9px;

// Wells
// -------------------------
@well-bg: @gray-lighter;

// Badges
// -------------------------
@badge-color: @whiteColor;
@badge-link-hover-color: @whiteColor;
@badge-bg: @gray-light;

@badge-active-color: @link-color;
@badge-active-bg: @whiteColor;

@badge-font-weight: bold;
@badge-line-height: 1;
@badge-border-radius: 10px;

// Breadcrumbs
// -------------------------
@breadcrumb-bg: @ultraLightGrey1Color;
@breadcrumb-color: @lightGreyColor;
@breadcrumb-active-color: @gray-light;
@breadcrumb-separator: "/";

// Carousel
// ------------------------

@carousel-text-shadow: 0 1px 2px rgba(0, 0, 0, .6);

@carousel-control-color: @whiteColor;
@carousel-control-width: 15%;
@carousel-control-opacity: .5;
@carousel-control-font-size: 20px;

@carousel-indicator-active-bg: @whiteColor;
@carousel-indicator-border-color: @whiteColor;

@carousel-caption-color: @whiteColor;

// Close
// ------------------------
@close-font-weight: bold;
@close-color: @trueBlackColor;
@close-text-shadow: 0 1px 0 @whiteColor;

// Code
// ------------------------
@code-color: @fushiaRedColor;
@code-bg: @fushiaRed10pColor;

@pre-bg: @ultraLightGrey2Color;
@pre-color: @gray-dark;
@pre-border-color: @lightGreyColor;
@pre-scrollable-max-height: 340px;

// Type
// ------------------------
@text-muted: @gray;
@abbr-border-color: @gray-light;
@headings-small-color: @gray-dark;
@blockquote-small-color: @gray;
@blockquote-border-color: @gray-lighter;
@page-header-border-color: @gray-lighter; //@gray-light;

// Miscellaneous
// -------------------------

// Hr border color
@hr-border: @gray-light;

// Horizontal forms & lists
@component-offset-horizontal: 180px;

// Container sizes
// --------------------------------------------------

// Small screen / tablet
@container-tablet: ((720px + @grid-gutter-width));
@container-sm: @container-tablet;

// Medium screen / desktop
@container-desktop: ((940px + @grid-gutter-width));
@container-md: @container-desktop;

// Large screen / wide desktop
@container-large-desktop: ((1140px + @grid-gutter-width));
@container-lg: @container-large-desktop;
